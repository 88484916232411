import { useNavigate, useParams } from 'react-router-dom';
import { PageWrapper } from '../PageWrapper';
import { BoardProvider, useBoardState } from '../../../context/boardContext';
import { useGetBoardQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import toast from 'react-hot-toast';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import { useFilterHook } from '../../hooks/FilterHook';
import { AppRoutes } from '../../../Routes';
import { AddBoardWidgetModal } from '../../components/Modals/AddBoardWidgetModal';
import { buildFilterInputFromSavedFilterInput } from '../ChartsPage';
import { WidgetsSection } from '../../components/boards/board/WidgetsSection';
import { BoardFilterSection } from './sections/BoardFilterSection';
import { BoardHeaderSection } from './sections/BoardHeaderSection';
import { useIsMount } from '../../../v2/util';

export const BoardPage = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { boardId: URLBoardId } = useParams();
  const boardId = Number(URLBoardId);
  const navigate = useNavigate();

  const { data: boardRes, loading: loadingBoard } = useGetBoardQuery({
    variables: {
      teamId,
      boardId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.boards, { replace: true });
  }, [teamId]);

  //Replace with real skeleton before launch
  if (loadingBoard) return <TempSkeletonPage />;

  if (!boardRes?.getBoard) {
    toast.error('Board not found');
    navigate(AppRoutes.v3FullPath.boards);
    return <></>;
  }

  return (
    <PageWrapper title="">
      <BoardProvider initialState={{ board: boardRes.getBoard }}>
        <BoardPageBody />
      </BoardProvider>
    </PageWrapper>
  );
};

const BoardPageBody = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();
  const [addWidgetModalOpen, setAddWidgetModalOpen] = useState(false);

  const filterHook = useFilterHook({
    teamId,
    orgId,
    startingFilterInput: buildFilterInputFromSavedFilterInput(state.board.filterInput ?? {}),
    respectUrlDatesOverStartingFilterInput: true
  });

  return (
    <>
      <AddBoardWidgetModal modalOpen={addWidgetModalOpen} setModalOpen={setAddWidgetModalOpen} />
      <div className="flex flex-col">
        <BoardHeaderSection />
        <BoardFilterSection
          filterHook={filterHook}
          leftSideUI={<Button variant={ButtonVariant.Bordered} shape={ButtonShape.Pill} text="Add widget +" onClick={() => setAddWidgetModalOpen?.(true)} />}
        />
        <WidgetsSection filterHook={filterHook} />
      </div>
    </>
  );
};

const TempSkeletonPage = () => {
  return (
    <div>
      <LoadingSpinner />
    </div>
  );
};
