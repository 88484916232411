import toast from 'react-hot-toast';
import { EXPORT_LIMIT_EXCEEDED } from '../../exports/Errors';
import { logError } from '../../applicationTelemetry';
import { ProgressState, Status } from '../../exports/ProgressMonitor';

export const getExportErrorHandler = (
  params: {
    teamId: number;
    groupIds: string[];
  },
  exportProgress: ProgressState,
  observer: MutationObserver,
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>
) => {
  return (error: Error) => {
    if (error.name === EXPORT_LIMIT_EXCEEDED) {
      toast.error(error.message);
    } else {
      toast.error(`Could not complete CSV export at this time.`);
    }
    logError(`Could not export group(s) ${params.groupIds.join(', ')} for team ${params.teamId}: ${error}`);
    updateExportProgress({ status: Status.error, percent: exportProgress.percent });
    observer.disconnect();
  };
};
