import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { classNames } from '../../v2/util';
import { Events, logEvent, Properties } from '../../v2/AnalyticsUtil';
import Tippy from '@tippyjs/react';

/*
This component should be expanded as we need more variants/sizes/utilities during migration. I setup the basic cases for now.
*/

export enum ButtonState {
  Default,
  Active,
}

export enum ButtonVariant {
  Primary,
  Secondary,
  Tertiary,
  Cancel,
  Theme,
  Icon,
  Back,
  PopupConfirm,
  PopupCancel,
  IconRaw,
  Bordered,
  IconRawWithHover
}

export enum ButtonSize {
  XSmall,
  Small,
  Regular,
  Large,
}

export enum ButtonShape {
  Pill,
  Square,
  Circle,
}

/**
 * loadingConfirm: boolean - if true, the button will show a spinner and the text will be replaced by loadingText
 * loadingText: string - the text to show when loadingConfirm is true
 */
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  variant?: ButtonVariant;
  shape?: ButtonShape;
  state?: ButtonState;
  children?: ReactNode;
  text?: string;
  loadingText?: string;
  icon?: ReactNode;
  expandWidth?: boolean;
  expandHeight?: boolean;
  locked?: boolean;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  disabledTooltip?: string;
  id?: string;
  loadingConfirm?: boolean;
  submit?: boolean;
  onClick?: () => void;
  //For logging events
  eventName?: Events | string;
  properties?: Properties;
  logClickEvent?: (eventName: Events, properties: Properties) => void;
  preventDefault?: boolean;
  hideIconOnLoading?: boolean;
  testid?: string;
}

const getButtonSizeClass = (size: ButtonSize, shape?: ButtonShape) => {
  const getPadding = (px: string, py: string) => (shape === ButtonShape.Circle ? `px-${py} py-${py}` : `px-${px} py-${py}`);

  switch (size) {
    case ButtonSize.Large:
      return `${getPadding('5', '3')} text-lg`;
    case ButtonSize.Regular:
      return `${getPadding('8', '2')} text-md`;
    case ButtonSize.Small:
      return `${getPadding('5', '1.5')} text-sm`;
    case ButtonSize.XSmall:
      return `${getPadding('3', '1')} text-xs`;
  }
};

const getButtonShapeClass = (shape: ButtonShape) => {
  switch (shape) {
    case ButtonShape.Pill:
      return 'rounded-full';
    case ButtonShape.Square:
      return 'rounded-xl';
    case ButtonShape.Circle:
      return 'rounded-full';
  }
};

const getButtonVariantClass = (variant: ButtonVariant, state: ButtonState) => {
  if (state === ButtonState.Active) {
    switch (variant) {
      case ButtonVariant.Primary:
        return 'active bg-blueberry-darker text-white';
      case ButtonVariant.Secondary:
        return 'active bg-raspberry-darker text-white';
      case ButtonVariant.Tertiary:
        return 'active bg-silver-darker text-blueberry';
      case ButtonVariant.Cancel:
        return 'active bg-blueberry border-milk border-2 border text-milk';
      case ButtonVariant.Icon:
        return 'active bg-silver-darker rounded-full';
      case ButtonVariant.IconRaw:
        return 'active text-blueberry';
      case ButtonVariant.Bordered:
        return 'bg-white border-blueberry border-2 text-blueberry';
    }
  } else {
    switch (variant) {
      case ButtonVariant.Primary:
        return 'bg-blueberry text-white enabled:hover:bg-blueberry-darker';
      case ButtonVariant.Secondary:
        return 'bg-raspberry text-white enabled:hover:bg-raspberry-darker';
      case ButtonVariant.Tertiary:
        return 'bg-silver text-blueberry enabled:hover:bg-silver-darker';
      case ButtonVariant.Cancel:
        return 'bg-milk enabled:hover:bg-silver border-blueberry border-2 border text-blueberry';
      case ButtonVariant.Icon:
        return 'bg-silver rounded-full';
      case ButtonVariant.IconRaw:
        return 'text-blueberry';
      case ButtonVariant.IconRawWithHover:
        return 'text-blueberry enabled:hover:bg-silver-lighter';
      case ButtonVariant.Bordered:
        return 'bg-white border-blueberry border-2 text-blueberry hover:bg-blueberry hover:text-white';
    }
  }
};

const Spinner = () => {
  return (
    <svg className="mr-2 h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Button = ({
  size = ButtonSize.Regular,
  variant = ButtonVariant.Primary,
  shape = ButtonShape.Square,
  state = ButtonState.Default,
  children,
  className,
  text,
  loadingText,
  icon,
  expandWidth,
  expandHeight,
  disabled,
  disabledTooltip,
  id,
  submit,
  onClick,
  eventName,
  properties,
  loadingConfirm,
  iconPosition,
  preventDefault,
  hideIconOnLoading,
  testid,
  ...props
}: ButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (preventDefault) e.preventDefault();
    onClick?.();
    if (eventName && properties) {
      logEvent(eventName, properties);
    }
  };

  return (
    <Tippy disabled={!disabled || !disabledTooltip} content={<h1>{disabledTooltip}</h1>}>
      <div className={classNames(expandWidth ? 'flex w-full' : '')} >
        <button
          id={id}
          data-testid={id}
          name={id}
          type={submit ? 'submit' : 'button'}
          onClick={handleClick}
          className={classNames(
            'flex flex-row items-center justify-center gap-x-2 whitespace-nowrap font-sofiapro font-semibold transition-all',
            'disabled:cursor-not-allowed disabled:opacity-50 duration-200',
            variant !== ButtonVariant.IconRaw && getButtonSizeClass(size, shape),
            getButtonShapeClass(shape),
            getButtonVariantClass(variant, state),
            expandWidth ? 'w-full' : 'w-fit',
            expandHeight ? 'h-full' : 'h-fit'
          )}
          disabled={disabled}
        >
          {(!hideIconOnLoading || !loadingConfirm) && icon && iconPosition === 'left' ? icon : null}
          {loadingConfirm && loadingText ? loadingText : text}
          {loadingConfirm ? <Spinner /> : null}
          {(!hideIconOnLoading || !loadingConfirm) && icon && iconPosition !== 'left' ? icon : null}
        </button>
      </div>
    </Tippy>
  );
};

export default Button;
