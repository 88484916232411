import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { truncate } from 'fs';
import { Fragment } from 'react';
import { ClusterUserRole } from '../../generated/graphql';

export interface IDropDownItem {
  name: string;
  id: number | string;
  /** We already have an id that's pretty pervasive in the code base. This just passes value to the html's id elements */
  htmlId?: string;
  displayName?: string;
  title?: string;
  value?: string;
  icon?: JSX.Element;
}

export enum Align {
  Left,
  Right,
}

interface DropDownProps {
  id?: string;
  dropDownData?: IDropDownItem[];
  selectedItem?: IDropDownItem;
  /** When an item is selected the drop down item is passed back. You don't have to pass in an object that is only {name, id} it
   * can have other fields. It just has to have name & id. When this callback is invoked the full object will be passed back as the
   * selectedItem.
   * Checkout where this is used in the AliasComponent and you can see what I mean.
   */
  setSelectedItem: (selectedItem: IDropDownItem) => void;
  disabled?: boolean;
  align?: Align;
  useDisplayName?: boolean;
  placeholderText?: string;
  fixedPosition?: boolean;
  fullWidthText?: boolean;
}

function classNames(...classes: any[]): string {
  return classes.filter(Boolean).join(' ');
}

const menuClassName = (align?: Align): string => {
  if (align === Align.Left) {
    return 'origin-top-left absolute mt-2 w-56 rounded-md shadow-md border border-silver bg-white z-40 outline-0 ring-0';
  }
  return 'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-md border border-silver bg-white z-40 outline-0 ring-0';
};

const DropDown = ({
  id,
  dropDownData,
  selectedItem,
  setSelectedItem,
  useDisplayName = false,
  disabled,
  align,
  placeholderText,
  fixedPosition,
  fullWidthText,
  ...props
}: DropDownProps): JSX.Element => {
  const selectedName = useDisplayName ? selectedItem?.displayName : selectedItem?.name ?? selectedItem?.title;
  return (
    <Menu as="div" className="relative text-left focus:outline-0" id={id}>
      <div>
        <Menu.Button
          className={classNames(
            'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium shadow-sm outline-0',
            disabled ? 'text-gray-300' : '  text-gray-700 hover:bg-gray-50'
          )}
          disabled={disabled}
        >
          <div className="flex flex-row items-center gap-x-2">
            {selectedItem?.icon}
            <span className="block truncate pr-4">
              {selectedName ? (
                fullWidthText ? (
                  selectedName
                ) : (
                  selectedName.slice(0, 16) + (selectedName.length > 16 ? '...' : '')
                )
              ) : (
                <i>{placeholderText || 'Choose an item...'}</i>
              )}
            </span>{' '}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </div>
          {/* <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuClassName(align)} style={{ ...(fixedPosition && { position: 'fixed' }) }}>
          <div className="max-h-64 overflow-y-auto py-1 ">
            {dropDownData?.map((item) => {
              return (
                <Menu.Item key={item.id}>
                  <div
                    id={item.htmlId}
                    className="block cursor-pointer break-words px-4 py-2 text-sm text-gray-900 duration-100 hover:bg-indigo-50"
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                  >
                    <div className="flex flex-row gap-x-2 items-center w-full">
                      {item.icon ? item.icon : null}
                      {item.displayName ?? item.name ?? item.title}
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
