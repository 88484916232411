import { TrashIcon } from '@heroicons/react/24/solid';
import { GroupEntryFragment, SentenceEntryFragment } from '../../../generated/graphql';

const SearchResult = ({
  entry,
  removeEntry,
  addEntry,
  isExcluded,
}: {
  entry: GroupEntryFragment & { mappedSentences: SentenceEntryFragment[] };
  removeEntry: (entryId: string) => void;
  addEntry: (entryId: string) => void;
  isExcluded: boolean;
}) => {
  const fullEntry = entry.mappedSentences[0].entry;
  const displayText = entry.mappedSentences[0].entry.distillateText ? entry.mappedSentences[0].entry.distillateText : entry.mappedSentences[0].text;
  return (
    <div
      className="flex flex-row justify-between items-center gap-x-3 cursor-pointer hover:bg-silver duration-150 transition-colors p-2"
      data-testid="search-result"
      onClick={(e) => {
        e.stopPropagation();
        isExcluded ? addEntry(fullEntry.id) : removeEntry(fullEntry.id);
      }}
    >
      <div className={`flex-grow ${isExcluded ? 'text-gray-400 line-through ' : ''}`}>{displayText}</div>
      <div data-testid="update-search-result-button" className="w-6 h-6 bg-blueberry flex flex-row justify-center items-center rounded-full flex-none">
        <TrashIcon className="w-4 h-4 text-milk" />
      </div>
    </div>
  );
};

export default SearchResult;
