import { Creator } from '../../../../v2/hooks/GroupHook';

interface CreatedByProps {
  creator: Creator;
}

const CreatedBy = ({ creator }: CreatedByProps) => {
  // If we don't have a creator email, we assume it's unwrap generated
  const isUnwrapGenerated = creator.isUnwrapGenerated || !creator.creatorEmail;
  return (
    <div className="flex flex-row items-center gap-x-1">
      <div className="text-gray-400">Created by:</div>
      <div>{isUnwrapGenerated ? 'Unwrap.ai' : creator.creatorEmail}</div>
    </div>
  );
};

export default CreatedBy;
