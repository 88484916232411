import { PageWrapper } from './PageWrapper';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IDropDownItem } from '../baseComponents/DropDown';
import { Breakdown, FilterInput, Y_Axis_Data, SavedFilterInput, Chart_Type, Chart_Bin_Type } from '../../generated/graphql';
import { EditChartSection } from '../components/EditChartSection';
import { Icon } from '@iconify/react';
import { defaultEndDate, defaultStartDate } from '../hooks/FilterHook';
import { AppRoutes } from '../../Routes';
interface ChartsPageProps {
  pageName: string;
}

export const ChartBinTypes: IDropDownItem[] = [
  { id: 0, name: 'Auto', value: Chart_Bin_Type.Dynamic },
  { id: 1, name: 'Daily', value: Chart_Bin_Type.Daily },
  { id: 2, name: 'Weekly', value: Chart_Bin_Type.Weekly },
  { id: 3, name: 'Monthly', value: Chart_Bin_Type.Monthly },
  { id: 4, name: 'Quarterly', value: Chart_Bin_Type.Quarterly },
];

export const ChartsPage = ({ pageName }: ChartsPageProps) => {
  return (
    <PageWrapper title={''}>
      <Routes>
        <Route path={`/:chartId/edit`} element={<EditChartSection mode="editor" />} />
        <Route path={`/creator`} element={<EditChartSection mode="creator" />} />
        <Route path={'*'} element={<Navigate to={AppRoutes.v3FullPath.home} replace />} />
      </Routes>
    </PageWrapper>
  );
};

export const PlotUnitMockList: IDropDownItem[] = [
  { id: 1, value: Y_Axis_Data.AbsoluteVolume, name: 'Absolute Volume' },
  { id: 2, value: Y_Axis_Data.RelativeShare, name: 'Percent of Feedback (%)' },
  { id: 3, value: Y_Axis_Data.Favorability, name: 'Positive Sentiment Rate (%)' },
];

export const BreakdownMockList: IDropDownItem[] = [
  { id: 0, value: undefined, name: 'None' },
  { id: 1, value: Breakdown.Source, name: 'Source' },
  { id: 2, value: Breakdown.Segment, name: 'Custom Field' },
  { id: 3, value: Breakdown.Sentiment, name: 'Sentiment' },
  { id: 4, value: Breakdown.Group, name: 'All Groups' },
  { id: 5, value: Breakdown.LeafGroup, name: 'Non-parent Groups' },
];

export const ChartTypeList: IDropDownItem[] = [
  { id: 1, value: Chart_Type.Line, name: 'Line', icon: <Icon icon="ph:chart-line" className="h-6 w-6" /> },
  { id: 2, value: Chart_Type.StackedArea, name: 'Stacked Area', icon: <Icon icon="carbon:chart-area" className="h-6 w-6" /> },
  { id: 3, value: Chart_Type.StackedBar, name: 'Stacked Column', icon: <Icon icon="carbon:chart-stacked" className="h-6 w-6" /> },
  { id: 4, value: Chart_Type.HorizontalBar, name: 'Horizontal Bar', icon: <Icon icon="carbon:chart-bar" className="h-6 w-6" /> },
  { id: 5, value: Chart_Type.Pie, name: 'Pie', icon: <Icon icon="ph:chart-donut" className="h-6 w-6" /> },
];

export function buildFilterInputFromSavedFilterInput(savedFilterInput: SavedFilterInput, startDate: Date = defaultStartDate, endDate: Date = defaultEndDate) {
  const newFilterInput: FilterInput = {
    startDate,
    endDate,
    sourceFitler: savedFilterInput?.sourceFilter?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        sources: savedFilter.sources!,
      };
    }),
    sentimentFilter: savedFilterInput?.sentimentFilter?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        sentiments: savedFilter.sentiments!,
      };
    }),
    groupFilter: savedFilterInput?.groupFilter?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        group: savedFilter.groups!.map((grp) => {
          return { id: grp.id };
        }),
      };
    }),
    segmentFilters: savedFilterInput?.segment?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        groupId: savedFilter.segmentGroupId ?? -1,
        segments: savedFilter.feedbackSegments ?? [],
      };
    }),
    minStarsFilter: savedFilterInput?.minStarFilter?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        amounts: savedFilter.amounts!,
      };
    }),
    maxStarsFilter: savedFilterInput?.maxStarFilter?.map((savedFilter) => {
      return {
        filterCondition: savedFilter.filterCondition!,
        amounts: savedFilter.amounts!,
      };
    }),
  };
  return newFilterInput;
}
