import React, { useState, useEffect, useContext } from 'react';
import { apolloClientBus, Channels } from '../../../ApolloClientBus';
import Modal from '../../baseComponents/Modal';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import { Dialog } from '@headlessui/react';
import { PermissionMessage } from './PermissionMessage';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AppContext from '../../../v2/contexts/AppContext';
import { AppRoutes } from '../../../Routes';

export const UnauthorizedModal: React.FC = () => {
  const { curOrgId } = useContext(AppContext);

  const [isVisible, setIsVisible] = useState(false);
  const [actionStatement, setActionStatement] = useState({ action: '', resource: '' });

  useEffect(() => {
    const showModal = (data: any) => {
      setIsVisible(true);
      if (data.actionStatement) {
        setActionStatement(data.actionStatement);
      }
    };

    apolloClientBus.subscribe(Channels.ERRORS, showModal);

    return () => {
      apolloClientBus.unsubscribe(Channels.ERRORS, showModal);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal open={isVisible} setOpen={() => setIsVisible(true)} title={''}>
      <div className="flex flex-col gap-y-8 mx-24">
        <Dialog.Title as="h3" className="flex flex-row justify-center items-center gap-x-4 text-3xl font-medium leading-6 text-raspberry-darker">
          <ExclamationTriangleIcon className="w-10 h-10" />
          <p>Access Denied</p>
        </Dialog.Title>
        <div className="modal text-center">
          <div className="modal-content flex flex-col gap-y-2">
            <p className={'text-blueberry text-lg'}>You don't have permission to perform this action.</p>
            <p className={'text-gray-500 font-medium flex-wrap'}>
              Please contact your Unwrap administrators to assign you the correct permissions. You administrators can be found on the
              {orgText(curOrgId)}
            </p>
          </div>
        </div>
        <div className="flex content-center justify-center gap-x-4 text-center mx-20">
          <Button
            expandWidth
            className={'center'}
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            text="Ok"
            onClick={() => {
              setIsVisible(false);
            }}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

const orgText = (curOrgId: number | null) => {
  return (
    <p>
      {curOrgId && curOrgId > 0 ? (
        <a className="italic underline text-blue-800" href={AppRoutes.v3FullPath.organizations + '/' + curOrgId}>
          Organizations Page.
        </a>
      ) : (
        ' Organizations Page.'
      )}
    </p>
  );
};
