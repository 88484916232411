import { useEffect, useState } from 'react';
import { Domain, Role, useAddOrganizationUsersMutation } from '../../../generated/graphql';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import DualToneModal from '../../baseComponents/DualToneModal';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { ViewPermissionsEditorSection } from '../orgInfo/ViewPermissionsEditorSection';
import { IDROPDOWN_ORG_ROLES } from '../orgInfo/utils';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../context/orgInfoContext';
import toast from 'react-hot-toast';
import { OrgInfoActionTypes } from '../../../reducers/orgInfo/orgInfoReducer';
import { PermissionsProvider, usePermissionsState } from '../../../context/roleAssignmentContext';
import { permissionMapToAssignedRolesForAUser } from '../../../reducers/orgInfo/permissions/permissionsReducer';
import { logError } from '../../../applicationTelemetry';
import { values, pick } from 'underscore';

interface InviteMemberModalProps {
  modalOpen: boolean;
  callbackModal: () => void;
}
export const InviteMemberModal = ({ modalOpen, callbackModal }: InviteMemberModalProps) => {
  return (
    <PermissionsProvider>
      <InviteMemberModalBody modalOpen={modalOpen} callbackModal={callbackModal} />
    </PermissionsProvider>
  );
};
const InviteMemberModalBody = ({ modalOpen, callbackModal }: InviteMemberModalProps) => {
  const { organization } = useOrgInfoState();
  const { permissionsMap } = usePermissionsState();
  const dispatch = useOrgInfoDispatch();
  const [selectedRole, setSelectedRole] = useState<Role>(Role.Member);
  const [inputEmail, setInputEmail] = useState<string>('');
  const [formDisabled, setFormDisabled] = useState(false);

  const [addUserToOrgMutation, addUserToOrgMutationRes] = useAddOrganizationUsersMutation();
  const handleInviteMember = async () => {
    await addUserToOrgMutation({
      variables: {
        orgId: organization.id,
        usersToAdd: [
          {
            userEmail: inputEmail,
            roles: [
              {
                domain: Domain.Org,
                domainId: organization.id,
                role: selectedRole,
              },
              ...permissionMapToAssignedRolesForAUser(permissionsMap),
            ],
          },
        ],
      },
      onCompleted(data) {
        const addedUser = data.addOrganizationUsers?.find((orgUser) => orgUser.email === inputEmail);
        if (addedUser) {
          toast.success('User invited successfully');
          dispatch({ type: OrgInfoActionTypes.AddMember, payload: { member: addedUser } });
          callbackModal();
        }
      },
      onError(error) {
        toast.error(`Failed to invite user: ${error.message}`);
        console.error(error);
        logError(error);
      }
    });
  };

  useEffect(() => {
    // this function will return false if no view-level role is selected in the permissionsMap
    // we do this by finding ORing the value of the view-level roles together. If at least one is checked, the whole expression is true.
    // ex:
    // assume: permissionsMap = {12: [{admin: false}, {contributor: false}, {viewer: false}, {replier: false}]}
    // get values for only view-level => [{contributor: false}, {viewer: false}, {replier: false}]
    // get values for view-level toggles => [false, false, false]
    // OR everything together => false
    const hasViewPermission = values(permissionsMap)
      .map((permission) => pick(permission, ['contributor', 'viewer', 'replier']))
      .map((roleToggled) => Object.values(roleToggled!))
      .flat()
      .reduce((previousValue, currentValue) => previousValue || currentValue, false);
    
    setFormDisabled(
      inputEmail === ''
      || addUserToOrgMutationRes.loading
      || (selectedRole === Role.Member && !hasViewPermission)
    );

  }, [addUserToOrgMutationRes.loading, inputEmail, permissionsMap, selectedRole]);

  const getFormButtons = () => {
    return (
      <div className="mt-4 flex flex-row justify-between gap-x-4 text-center w-full">
        <div className="flex w-1/3">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={callbackModal} text="Cancel" expandWidth></Button>
        </div>
        <div className="flex w-2/3">
          <Button
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            data-testid={'invite'}
            text={'Invite'}
            onClick={handleInviteMember}
            expandWidth
            loadingConfirm={addUserToOrgMutationRes.loading}
            disabled={formDisabled}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <DualToneModal
      open={modalOpen}
      setOpen={callbackModal}
      headerChildren={
        <div className="flex flex-col items-center text-center gap-y-5">
          <h1 className="text-3xl font-medium">
            Invite members to <b>{organization.name}</b>
          </h1>
          <p className="text-gray-500">Your teammate will get an email that gives them access to your organization and all the views inside it.</p>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col justify-between text-blueberry w-full gap-y-5">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-1">
                <h1 className="text-sm">Email</h1>
                <input
                  value={inputEmail}
                  data-testid={'email'}
                  onChange={(e) => setInputEmail(e.target.value)}
                  autoComplete="off"
                  aria-autocomplete="none"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <h1 className="text-sm">Role</h1>
                <VirtualizedComboBox
                  comboBoxData={IDROPDOWN_ORG_ROLES}
                  disableClear
                  selectedItem={IDROPDOWN_ORG_ROLES.find((role) => role.value === selectedRole)}
                  setSelectedItem={(item) => item && setSelectedRole(item.value as Role)}
                  placeholder={'Select role'}
                  data-testid="invite-role-select"
                />
              </div>
              {selectedRole === Role.Member ? <ViewPermissionsEditorSection /> : null}
            </div>
          </div>
          {getFormButtons()}
        </div>
      }
    />
  );
};
