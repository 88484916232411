import { PlusIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';

interface EntryAddIconProps {
  addEntryToParentComponentContext?: (entryId: string) => Promise<void>;
  entryId: string;
}

/**
 *
 * This is the Add entry path that support the 'Add Similar Entries' tab on the GroupPage
 *
 * The expectation / assumption here is whoever the caller is is responsible for:
 * 1. making the api request to add the entry
 * 2. managing whatever you want to do to show / not show this entry in the list
 * @param param0
 * @returns
 */
export const EntryAddIcon = ({ addEntryToParentComponentContext, entryId }: EntryAddIconProps) => {
  const [loadingAdd, setLoadingAdd] = useState(false);
  if (!addEntryToParentComponentContext) return null;

  if (loadingAdd) {
    return (
      <div
        data-testid="sentence-add-loading"
        className="z-10 absolute text-sm flex flex-row items-center gap-x-2 -right-2 -top-2 duration-100 p-0.5 px-2 bg-blueberry-lighter rounded-full cursor-pointer text-milk opacity-80"
      >
        <p>Adding Entry...</p>
        <AdjustableLoadingIcon color={'text-milk'} width={4} height={4} />
      </div>
    );
  }

  return (
    <div
      className="z-10 absolute text-sm flex flex-row items-center gap-x-2 -right-2 -top-2 opacity-0 transition-opacity duration-100  p-0.5 px-2 bg-blueberry-lighter rounded-full cursor-pointer text-milk hover:bg-blueberry group-hover:opacity-80"
      onClick={async (e) => {
        setLoadingAdd(true);
        await addEntryToParentComponentContext(entryId);
        setLoadingAdd(false);
      }}
    >
      <p>Add Entry to Group</p>
      <PlusIcon id="add-group-sentence" className="h-5 w-5" />
    </div>
  );
};
