import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import {
  Breakdown,
  ChartFragment,
  Chart_Bin_Type,
  Chart_Type,
  FilterInput,
  SavedFilterInput,
  SeriesInput,
  Y_Axis_Data,
  useDataForFiltersLazyQuery,
  useGetBoardLazyQuery,
  useGetBoardQuery,
  useGetCustomChart2DLazyQuery,
} from '../../generated/graphql';
import { useFilterHook } from '../hooks/FilterHook';
import UserContext from '../../v2/contexts/UserContext';
import { useCustomChartHook } from '../hooks/CustomChartHook';
import { areFiltersEmpty, useIsMount } from '../../v2/util';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IDropDownItem } from '../baseComponents/DropDown';
import { cloneDeep } from 'lodash';
import { BreakdownMockList, ChartBinTypes, ChartTypeList, PlotUnitMockList, buildFilterInputFromSavedFilterInput } from '../pages/ChartsPage';
import { AppRoutes } from '../../Routes';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../baseComponents/Button';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import { DataCard } from './ChartDataCard';
import Tippy from '@tippyjs/react';
import { AdjustmentsHorizontalIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FullCustomChartCard } from './CustomChartCard';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';
import toast from 'react-hot-toast';
import { VirtualizedComboBox } from './VirtualizedComboBox';
import { SmallSpinner } from './SmallSpinner';

export const EditChartSection = ({ mode }: { mode: 'editor' | 'creator' }) => {
  const { curTeamId: teamId, curOrgId: orgId, organizations } = useValidTeamAppContext();
  const [binType, setBinType] = useState(Chart_Bin_Type.Dynamic);
  const teams = organizations.find((org) => org.id === orgId)?.teams;
  const teamsList = teams?.map((team) => ({ id: team.id, name: team.name })) || [];
  //This hook is used for the visualization
  const previewFilterHook = useFilterHook({ teamId, orgId, filterKey: 'group' });
  //This hook is used for the chart's settings
  const editChartFilterHook = useFilterHook({ teamId, orgId, filterKey: 'group' });
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [getBoard] = useGetBoardLazyQuery({});

  const [shouldRenderInheritedFilters, setShouldRenderInheritedFilters] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const boardId = queryParams.get('boardId');
  const [boardFiltersFetched, setBoardFiltersFetched] = useState(false);
  const [boardFilterInput, setBoardFilterInput] = useState<FilterInput | null>(null);
  useEffect(() => {
    if (boardId) {
      getBoard({
        //If you come from a Board, this data is already cached.
        variables: { teamId, boardId: Number(boardId) },
        onCompleted(data) {
          const boardSavedFilter = data?.getBoard?.filterInput;
          if (!boardSavedFilter) return;
          const boardFilterInput = buildFilterInputFromSavedFilterInput(boardSavedFilter);
          setShouldRenderInheritedFilters(!areFiltersEmpty(boardFilterInput));
          setBoardFilterInput(boardFilterInput);
          //We want set filters to the boards' but preserve the start and end date (boards don't store dates)
          previewFilterHook.setFilters({ ...boardFilterInput, startDate: previewFilterHook.filters.startDate, endDate: previewFilterHook.filters.endDate });
          setBoardFiltersFetched(true);
        },
        onError() {
          setBoardFiltersFetched(true);
        },
      });
    }
  }, []);

  const { currentChart, createCustomChart, loadingStatues, setChartConfigs, chartConfigs, editCustomChart } = useCustomChartHook({
    teamId,
    orgId,
    filterInput: editChartFilterHook.filters,
    dateFilterInput: previewFilterHook.filters,
    mode,
    binType,
    initialLoadComplete,
    boardId: boardId ? Number(boardId) : undefined,
  });
  const isFirstRender = useIsMount();
  useEffect(() => {
    //This checks if the user edited the chart. In that case, we want to display the edited chart and no longer the original chart.
    if (mode === 'editor' && displayReturnedChart && initialLoadComplete) setDisplayReturnedChart(false);
  }, [currentChart]);

  //This state helps decrease latency by displaying the current chart in case it's in editor mode, so we don't have to wait until the Preview query (which finishes after the Chart query)
  const [displayReturnedChart, setDisplayReturnedChart] = useState(mode === 'editor');
  const { chartId } = useParams<{ chartId: string }>();
  const [dataFilters, setDataFilters] = useState<SeriesInput[]>([{ teamIdOverride: teamId }]);
  const [getFilterData, filterData] = useDataForFiltersLazyQuery({ variables: { teamId, orgId } });
  const [segments, setSegments] = useState<IDropDownItem[] | undefined>();
  const settingsFilterManagerRef = useRef<any>(null);
  const [loadingChart, setLoadingChart] = useState(false);

  const [getCustom2DChart, custom2DChart] = useGetCustomChart2DLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [customChart, setCustomChart] = useState<ChartFragment | undefined>();
  useEffect(() => {
    let updatedInputs = cloneDeep(dataFilters);
    updatedInputs = updatedInputs.map((filter) => {
      return {
        ...filter,
        filterInput: { ...filter.filterInput, startDate: previewFilterHook.filters?.startDate, endDate: previewFilterHook.filters?.endDate },
      };
    });
    setDataFilters(updatedInputs);
  }, [previewFilterHook.filters]);

  const fetchCustomChart = async () => {
    setLoadingChart(true);
    await getCustom2DChart({
      variables: { teamId, chartId: Number(chartId), filterInput: previewFilterHook.filters },
      onCompleted(data) {
        //what the hell is going on here
        const chart: ChartFragment = {
          id: data.getChart2D.id,
          title: data.getChart2D.title,
          type: data.getChart2D.config.type,
          y_axis_data: data.getChart2D.config.y_axis_data,
          breakdown: data.getChart2D.config.breakdown,
          seriesData: data.getChart2D.config.seriesConfig?.map((series, idx, arr) => {
            return {
              aggregateData: arr.length > 1 ? [data.getChart2D.series[idx]] : data.getChart2D.series,
              normalizedData: arr.length > 1 ? [data.getChart2D.series[idx]] : data.getChart2D.series,
              tooltipLabels: data.getChart2D.tooltipLabels,
              breakdown: data.getChart2D.config.breakdown,
              chartLabels: data.getChart2D.xAxisLabels,
              breakdownLabels: arr.length > 1 ? [data.getChart2D.legend[idx]] : data.getChart2D.legend,
              filterInput: series.filterInput,
              team: series.team,
              segmentGroupId: series.segmentGroupId,
            };
          }),
        };
        if (chart.seriesData) {
          settingsFilterManagerRef?.current?.refreshUiFilters(editChartFilterHook.filters);
        }
        const seriesData =
          chart.seriesData?.map((sd) => ({
            filterInput: {
              ...buildFilterInputFromSavedFilterInput(sd.filterInput ?? {}, editChartFilterHook.filters.startDate, editChartFilterHook.filters.endDate),
            },
            teamIdOverride: sd.team.id,
            segmentGroupId: sd.segmentGroupId,
          })) ?? [];
        setChartConfigs((prev) => {
          return {
            ...prev,
            y_axis_data: PlotUnitMockList.find((item) => item.value === chart?.y_axis_data)?.value as Y_Axis_Data,
            breakdown: BreakdownMockList.find((item) => item.value === chart?.breakdown || item.value === chart?.seriesData?.[0].breakdown)?.value as Breakdown,
            title: chart?.title ?? '',
            series: seriesData,
            type: ChartTypeList.find((item) => item.value === chart?.type)?.value as Chart_Type,
          };
        });
        setLoadingChart(false);
        setDataFilters(seriesData);
        setInitialLoadComplete(true);
        setCustomChart(chart);
      },
      onError(error) {
        toast.error('Error fetching chart data');
        setLoadingChart(false);
        navigateToBoard();
      },
    });
  };

  const getSegments = () => {
    getFilterData({
      onCompleted(data) {
        setSegments(
          data.segments?.map((segment) => {
            return { id: segment.id, name: segment.displayName };
          })
        );
      },
    });
  };

  useEffect(() => {
    getSegments();
    if (chartId)
      fetchCustomChart().then(() => {
        setInitialLoadComplete(true);
      });
    else setInitialLoadComplete(true);
  }, []);

  useEffect(() => {
    // Not super nice. Revisit.
    if (!isFirstRender) setChartConfigs((prev) => ({ ...prev, breakdown: dataFilters.length === 1 ? prev?.breakdown : undefined, series: dataFilters }));
    dataFilters.forEach((filter) => {
      settingsFilterManagerRef?.current?.refreshUiFilters(filter.filterInput);
    });
  }, [dataFilters]);
  let navigate = useNavigate();

  const updateFilterInputs = (filterInput: SeriesInput, index: number) => {
    const updateInputs = cloneDeep(dataFilters);
    updateInputs[index] = filterInput;
    setDataFilters(updateInputs);
  };
  const deleteFilterInput = (teamId: number, index: number) => {
    setDataFilters((prev) => prev.filter((f, i) => !(f.teamIdOverride === teamId && i === index)));
  };

  const handleCancelClick = () => {
    navigateToBoard();
  };

  const navigateToBoard = () => {
    let navigatePath;
    if (boardId) navigatePath = `${AppRoutes.v3FullPath.boards}/${boardId}`;
    else navigatePath = `${AppRoutes.v3FullPath.boards}`;
    navigate(navigatePath);
  };

  const handleSaveChart = async () => {
    mode === 'creator' ? await createCustomChart(() => navigateToBoard()) : await editCustomChart(Number(chartId), () => navigateToBoard());
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row gap-y-4 justify-between mb-4 gap-x-8 ">
        <div className="flex flex-row items-center gap-x-2 w-full">
          <Button
            id="back-button"
            variant={ButtonVariant.Tertiary}
            shape={ButtonShape.Pill}
            size={ButtonSize.Small}
            text="Back"
            onClick={() => navigateToBoard()}
            icon={<p>{'<'}</p>}
            iconPosition="left"
          />
          <div className="w-full">
            <InlineEditableTextArea
              placeholder={'Enter a title...'}
              ignorePadding
              textAreaId="chart-title"
              value={chartConfigs?.title ?? ''}
              onEditComplete={(newText: string) => {
                setChartConfigs((prev) => {
                  return { ...prev, title: newText };
                });
              }}
              additionalClassNames={
                'items-center text-blueberry h-auto focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
              }
              textClassName="text-3xl xl:text-4xl font-semibold"
            />
          </div>
        </div>
        <div className="flex flex-row gap-x-2 items-center">
          <Button
            id="cancel-chart-creation"
            shape={ButtonShape.Pill}
            size={ButtonSize.Small}
            variant={ButtonVariant.Tertiary}
            text="Cancel"
            onClick={handleCancelClick}
          />
          <Button
            id="save-chart"
            shape={ButtonShape.Pill}
            size={ButtonSize.Small}
            variant={ButtonVariant.Primary}
            loadingConfirm={loadingStatues.creatingChart}
            text="Save Chart"
            onClick={async () => {
              await handleSaveChart();
            }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-x-4 mb-8 items-center">
        <EditChartInputDropdown name="Type">
          <div className="w-fit">
            <VirtualizedComboBox
              id="chart-type-dropdown"
              comboBoxData={ChartTypeList}
              grayStyle
              disableAlphabeticalSort
              disableClear
              setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                if (!selectedItem) return;
                setChartConfigs((prev) => {
                  return { ...prev, type: selectedItem.value as Chart_Type };
                });
              }}
              selectedItem={ChartTypeList.find((item) => item.value === chartConfigs?.type)}
            />
          </div>
        </EditChartInputDropdown>
        <EditChartInputDropdown name="Plot Unit">
          <div className="w-60">
            <VirtualizedComboBox
              id="chart-plotunit-dropdown"
              comboBoxData={PlotUnitMockList}
              grayStyle
              disableAlphabeticalSort
              disableClear
              setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                if (!selectedItem) return;

                setChartConfigs((prev) => {
                  return { ...prev, y_axis_data: selectedItem.value as Y_Axis_Data };
                });
              }}
              selectedItem={PlotUnitMockList.find((item) => item.value === chartConfigs?.y_axis_data)}
            />
          </div>
        </EditChartInputDropdown>
        <EditChartInputDropdown name="Breakdown">
          <div className="w-fit flex flex-row gap-x-2">
            <Tippy theme="dark" disabled={dataFilters.length <= 1} content={<p>You can only break down when there's one data series in the chart</p>}>
              <div>
                <VirtualizedComboBox
                  id="chart-breakdown-dropdown"
                  grayStyle
                  disableAlphabeticalSort
                  disableClear
                  disabled={dataFilters.length > 1}
                  comboBoxData={segments && segments.length > 0 ? BreakdownMockList : BreakdownMockList.filter((b) => b.value !== Breakdown.Segment)}
                  setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                    if (!selectedItem) return;
                    setChartConfigs((prev) => {
                      return { ...prev, breakdown: selectedItem.value as Breakdown };
                    });
                    const updatedDataFilters = cloneDeep(dataFilters);
                    updatedDataFilters[0].segmentGroupId = selectedItem.value === Breakdown.Segment ? updatedDataFilters[0].segmentGroupId : undefined;
                    setDataFilters(updatedDataFilters);
                  }}
                  selectedItem={BreakdownMockList.find((item) => item.value === chartConfigs?.breakdown)}
                />
              </div>
            </Tippy>

            {chartConfigs?.breakdown === Breakdown.Segment ? (
              <div className="w-fit">
                <VirtualizedComboBox
                  id="chart-segment-breakdown-dropdown"
                  grayStyle
                  disableAlphabeticalSort
                  disableClear
                  comboBoxData={segments ?? []}
                  setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                    const updatedDataFilters = cloneDeep(dataFilters);
                    updatedDataFilters[0].segmentGroupId = Number(selectedItem?.id);
                    setDataFilters(updatedDataFilters);
                  }}
                  selectedItem={segments?.find((segment) => {
                    return segment.id === chartConfigs.series?.[0].segmentGroupId;
                  })}
                />
              </div>
            ) : null}
          </div>
        </EditChartInputDropdown>
        <div className="flex-auto border-t-2 border-gray-300 mt-4"></div>
      </div>

      <div className="grid grid-cols-12 gap-y-8 xl:gap-y-0 xl:divide-x-2 xl:divide-gray-100 rounded-3xl w-full">
        {!initialLoadComplete || custom2DChart.loading || !boardFiltersFetched ? (
          <div className="col-span-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="col-span-12 xl:col-span-4 xl:pr-4 flex flex-col gap-y-5 relative">
              <EditChartDataSection
                onAdd={
                  dataFilters.length <= 10
                    ? () => {
                        setDataFilters((prev) => [...prev, { teamIdOverride: teamsList[0].id }]);
                      }
                    : undefined
                }
              />
              {boardFilterInput && shouldRenderInheritedFilters ? <InheritedBoardFiltersSection filterInput={boardFilterInput} /> : null}
              {dataFilters.map((filter, index) => {
                return (
                  <DataCard
                    key={index}
                    teamsList={teamsList}
                    settingsFilterManagerRef={settingsFilterManagerRef}
                    teamId={filter.teamIdOverride ?? teamId}
                    filter={filter}
                    updateFilterInputs={(filterInput) => updateFilterInputs(filterInput, index)}
                    deleteFilterInput={(teamId: number) => deleteFilterInput(teamId, index)}
                    index={index}
                    dataFilters={dataFilters}
                    setDataFilters={(dataFilters) => setDataFilters(dataFilters)}
                  />
                );
              })}
            </div>
            <div className="col-span-12 xl:col-span-8 xl:pl-4 ">
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row gap-x-4 items-center">
                    <h1 className="text-lg font-semibold text-blueberry">Chart</h1>
                  </div>
                  {loadingStatues.generatingPreview ? (
                    <div className="flex flex-row justify-center gap-x-1 px-2 rounded-md items-center text-blueberry">
                      <SmallSpinner />
                      <p>Loading...</p>
                    </div>
                  ) : null}
                  <div className="flex flex-row justify-end gap-x-3">
                    <Tippy theme="dark" content="Change the x-axis bin">
                      <div className="w-28">
                        <VirtualizedComboBox
                          id="chart-bin-type-dropdown"
                          grayStyle
                          disableAlphabeticalSort
                          disableClear
                          selectedItem={ChartBinTypes.find((item) => item.value === binType)}
                          comboBoxData={ChartBinTypes}
                          setSelectedItem={(item) => {
                            if (!item) return;
                            if (item.value) {
                              setBinType(item.value as Chart_Bin_Type);
                            }
                          }}
                        />
                      </div>
                    </Tippy>
                    <FilterManager
                      pageName={''}
                      filterHook={previewFilterHook}
                      dataTypeToFilter={'chartEditor'}
                      displayMode={FilterManagerDisplayMode.ChartEditorPreview}
                      filterButtonText="Add Filter"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-96" id="section">
                  {!displayReturnedChart ? (
                    currentChart ? (
                      <FullCustomChartCard
                        key={currentChart.id}
                        hideHeader
                        chartData={currentChart}
                        filterInput={editChartFilterHook.filters}
                        newCard={true}
                        loading={loadingStatues.creatingChart}
                      />
                    ) : (
                      <LoadingSpinner />
                    )
                  ) : null}
                  {mode === 'editor' && displayReturnedChart ? (
                    customChart ? (
                      <FullCustomChartCard
                        key={customChart.id}
                        hideHeader
                        chartData={customChart}
                        filterInput={editChartFilterHook.filters}
                        newCard={true}
                        loading={loadingStatues.creatingChart}
                      />
                    ) : (
                      <LoadingSpinner />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const EditChartDataSection = ({ onAdd, onRemove }: { onAdd?: () => void; onRemove?: () => void }) => {
  return (
    <div className="flex flex-row justify-between items-center text-blueberry">
      <h1 className="font-semibold text-lg">Data</h1>
      {onAdd ? (
        <Tippy theme="dark" content={<p>You can add a new data series to the chart to compare data between views</p>}>
          <div className="add-chart-data-series cursor-pointer" onClick={onAdd}>
            <PlusIcon className="h-4 w-4 stroke-2" />
          </div>
        </Tippy>
      ) : null}
      {onRemove ? (
        <Tippy theme="dark" content={<p>Remove current data series from chart</p>}>
          <div className="remove-chart-data-series cursor-pointer" onClick={onRemove}>
            <XMarkIcon className="h-4 w-4 stroke-2" />
          </div>
        </Tippy>
      ) : null}
    </div>
  );
};

export const EditChartInputDropdown = ({ name, children }: { name: string; children?: React.ReactNode; onAdd?: () => void; onRemove?: () => void }) => {
  return (
    <div className="flex flex-col">
      <p className="text-gray-600 italic text-sm">{name}</p>
      <>{children}</>
    </div>
  );
};

const InheritedBoardFiltersSection = ({ filterInput }: { filterInput: FilterInput }) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const filterHook = useFilterHook({
    teamId,
    orgId,
    startingFilterInput: filterInput,
  });

  return (
    <div className="w-full p-4 rounded-md border border-gray-300">
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-x-1 items-center">
              <AdjustmentsHorizontalIcon className="h-5 w-5 stroke-2" />
              <h1 className="text-lg font-semibold text-blueberry">Inherited Filters</h1>
            </div>
            {/* To implement during polish */}
            {/*  <Toggle initialState={true} onSwitch={toggleBoardFilters} /> */}
          </div>
          <p className="text-gray-600 font-light text-sm">These filters come from the Board this chart belongs to.</p>
        </div>
        <FilterManager
          pageName={''}
          filterHook={filterHook}
          dataTypeToFilter={'boardPage'}
          displayMode={FilterManagerDisplayMode.OnlyFiltersShown}
          disableFilterEditing={true}
        />
      </div>
    </div>
  );
};
