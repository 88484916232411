import { useContext, useEffect, useState } from 'react';
import Badge from '../../components/Badge';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import DropDown, { IDropDownItem } from '../../baseComponents/DropDown';
import Form from '../../../baseComponents/Form';
import Modal from '../../baseComponents/Modal';
import { ICategoryItem, IFilter } from './FiltersTypes';
import { FilterType, useDataForFiltersLazyQuery } from '../../../generated/graphql';
import { addFilter, FilterableDataTypes, getCategoryDropDown, getPlaceholderText, getSelectedCategoryDropDown, removeFilter } from './FiltersUtil';
import UserContext from '../../../v2/contexts/UserContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { VirtualizedComboBox } from '../../components/VirtualizedComboBox';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { SelectFilterDropdown } from './SelectFilterDropdown';
interface FilterModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  currentFilter?: IFilter;
  setCurrentFilter: (filter: IFilter) => void;
  saveFilter: (filter: IFilter, teamId: number) => void;
  page: string;
  dataTypeToFilter: FilterableDataTypes;
  teamIdOverride?: number;
}

interface IAndOrFilter extends IDropDownItem {
  filterType: FilterType;
}

const andOrDropDown: IAndOrFilter[] = [
  { name: 'AND', id: 0, filterType: FilterType.And },
  { name: 'OR', id: 1, filterType: FilterType.Or },
];

const FilterModal = ({
  modalOpen,
  setModalOpen,
  currentFilter,
  setCurrentFilter,
  saveFilter,
  page,
  dataTypeToFilter,
  teamIdOverride,
}: FilterModalProps): JSX.Element => {
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const [getFilterOptions, filterOptions] = useDataForFiltersLazyQuery({ variables: { teamId: teamIdOverride ? teamIdOverride : teamId, orgId } });
  const [selectedCategory, setSelectedCategory] = useState<IDropDownItem | undefined>(getCategoryDropDown(undefined, dataTypeToFilter)[0]);
  const [selectedFilter, setSelectedFilter] = useState<IDropDownItem | undefined>({ name: '', id: 0 });
  const [selectedFilterType, setSelectedFilterType] = useState<IDropDownItem>(andOrDropDown[0]);
  const resetSelectedCategories = () => {
    setSelectedCategory(getCategoryDropDown(undefined, dataTypeToFilter)[0]);
    setSelectedFilter({ name: '', id: 0 });
    setSelectedFilterType(andOrDropDown[0]);
  };
  useEffect(() => {
    resetSelectedCategories();
    getFilterOptions({ variables: { teamId: teamIdOverride ? teamIdOverride : teamId, orgId } });
  }, [teamId, teamIdOverride]);
  const setDropDowns = (): void => {
    if (!currentFilter) {
      setSelectedFilter({ name: '', id: 0 });
    } else {
      if (filterOptions.data) {
        if (currentFilter) {
          const categories = getCategoryDropDown(filterOptions.data, dataTypeToFilter);
          const newCategory = categories.find((category) => {
            return category.realId === currentFilter.filterCategoryId && category.categoryType === currentFilter.filterCategory;
          });
          setSelectedCategory(newCategory);
        }
        setSelectedFilter({ name: '', id: 0 });
        setSelectedFilterType(andOrDropDown.find((val) => val.filterType === currentFilter.filterCondition) ?? andOrDropDown[0]);
      }
    }
  };
  useEffect(() => setDropDowns(), [currentFilter]);
  const getFormButtons = () => {
    return (
      <div className="mt-8 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={() => setModalOpen(false)} text="Cancel" expandWidth></Button>
        </div>
        <div className="col-span-2">
          <Button
            id="set-filters-confirm"
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            text="Confirm"
            onClick={() => {
              if (currentFilter) {
                saveFilter(currentFilter, teamId);
              }
              setModalOpen(false);
            }}
            disabled={currentFilter === undefined}
            expandWidth
            eventName={'filter-added'}
            properties={{
              View_ID: teamId,
              View_Name: currentTeam.name,
              Org_ID: orgId,
              Org_Name: currentOrg.name,
              Page: 'Explore',
              Filter_Category: (selectedCategory as ICategoryItem)?.categoryType,
            }}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={setModalOpen}>
      <div className="min-h-1000" data-testid="filter-modal">
        <div className="flex items-center justify-center">
          <h1 className="text-xl font-semibold text-blueberry">Add Filter</h1>
        </div>
        {!filterOptions.data || filterOptions.loading ? (
          <LoadingSpinner />
        ) : (
          <Form bottomRow={getFormButtons()}>
            {/* Category drop down */}
            <div className="flex flex-row justify-center items-center gap-x-3 gap-y-2">
              {/* If current filter is set you need to disable this drop down.  */}
              <div className="w-auto min-w-[11rem] flex-grow" id="category-dropdown-container">
                <DropDown
                  fullWidthText={selectedCategory ? true : false}
                  dropDownData={getCategoryDropDown(filterOptions.data, dataTypeToFilter)}
                  selectedItem={selectedCategory}
                  disabled={currentFilter ? currentFilter.values.length >= 1 : false}
                  setSelectedItem={(item) => {
                    setSelectedCategory(item);
                    setSelectedFilter({ name: '', id: 0 });
                  }}
                />
              </div>
              <div className="h-full flex-grow" id="categoryOption-dropdown-container">
                <SelectFilterDropdown
                  selectedCategory={selectedCategory}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={(filter) => setSelectedFilter(filter)}
                  comboBoxData={getSelectedCategoryDropDown(selectedCategory as ICategoryItem, filterOptions.data, user)}
                />
              </div>
              <div className="items-center min-w-[6rem]">
                <Button
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  shape={ButtonShape.Pill}
                  text="Add +"
                  expandWidth
                  onClick={() => {
                    if (!selectedFilter) return;

                    //@ts-ignore
                    addFilter(selectedCategory as ICategoryItem, selectedFilter, setCurrentFilter, currentFilter);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 pt-5 sm:grid-cols-3 ">
              <div className="sm:col-span-2 ">
                <div className="flex flex-wrap">
                  {currentFilter?.values.map((value, index) => {
                    return (
                      <div className="pr-2 pb-1" key={index}>
                        <Badge
                          badge={{ text: value.displayName ?? value.title, id: value.uiId }}
                          onEdit={() => {}}
                          onRemove={(item) => {
                            removeFilter(item, setCurrentFilter, currentFilter);
                          }}
                          color="bg-silver"
                          textColor="text-blueberry"
                          capitalize={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="sm:col-span-1">
                {currentFilter?.values ? (
                  currentFilter.values.length > 1 ? (
                    <div className="flex flex-row justify-end">
                      <DropDown
                        dropDownData={andOrDropDown}
                        selectedItem={selectedFilterType}
                        setSelectedItem={(item) => {
                          setSelectedFilterType(item);
                          setCurrentFilter({ ...currentFilter, filterCondition: (item as IAndOrFilter).filterType });
                        }}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default FilterModal;
