import Tippy from '@tippyjs/react';

export function GroupMentionData(props: {
  totalEntries: number;
  filteredStatsMode: boolean;
  relativeShare: number | undefined;
  relativeShareFull: number | undefined;
}) {
  const isFiltered = props.filteredStatsMode;
  const feedbackCount = (isFiltered ? props.relativeShare : props.relativeShareFull) ?? 0;

  const feedbackCountString = `${feedbackCount.toFixed(1)}% of ${isFiltered ? 'filtered' : 'all'} feedback`;
  return (
    <h4 className="text-sm">
      <div className="flex flex-row gap-x-1">
        {props.totalEntries.toLocaleString('en-US')} mention{props.totalEntries === 1 ? '' : 's'}{' '}
        <Tippy theme="dark" delay={200} content={<p className="text-center">{feedbackCountString}</p>}>
          <p className="text-sm text-gray-400">({feedbackCountString})</p>
        </Tippy>
      </div>
    </h4>
  );
}
