import { useContext, useEffect, useReducer, useState } from 'react';
import { Group_Status, TaxonomyTreeNode, useAmountOfGroupsLazyQuery, useGroupSearchLazyQuery, useTeamGroupsTaxonomyLazyQuery } from '../../generated/graphql';
import { FilterHook } from '../hooks/FilterHook';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { ExploreGroupHook, ExplorePageView } from '../hooks/ExploreGroupHook';
import { PageWrapper } from './PageWrapper';
import GroupPreview from '../components/GroupPreview';
import { useInView } from 'react-intersection-observer';
import AnnouncementModal from '../components/Modals/AnnouncementModal';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { GroupDeleter } from '../components/GroupDeleter';
import { GroupFull } from '../../v2/hooks/GroupHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { TaxonomyToggle } from '../components/TaxonomyToggle';
import { TaxonomyChildrenToggle } from '../components/TaxonomyChildrenToggle';
import { TaxonomyView } from '../components/TaxonomyView';
import UserContext from '../../v2/contexts/UserContext';
import { AppRoutes } from '../../Routes';
import EmployeeOnlyComponent from '../baseComponents/EmployeeOnlyComponent';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { pageSize } from './explore/ExplorePageRouter';
import { TaxonomyAction } from '../actions/taxonomy';
import { getTotalPageLoadEvent } from '../../latencyTracker';
import { getGroupPageUrl } from '../lib/groups';
import { SearchPreviewContext } from '../context/SearchPreviewContext';
import { SearchPreviewDispatchContext } from '../context/SearchPreviewDispatchContext';
import { defaultGroup } from '../lib/searchPreview';
import { searchPreviewReducer, SearchPreviewReducerResult } from '../reducers/searchPreview';
import { GroupSemanticSearchResults } from '../components/GroupSemanticSearchResults';
import { toTaxonomyMap } from '../lib/taxonomy';
import toast from 'react-hot-toast';
import { GroupCount } from '../components/taxonomy/GroupCount';
import TrendingNewButton from '../components/Search/TrendingNewButton';
import SearchSection from '../components/Search/SearchSection';
import SearchPreviewModal from '../components/Search/SearchPreviewModal';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';

export enum GroupUIType {
  Card,
  Modal,
  PreviewPage,
}

interface ExplorePageProps {
  pageName: string;
  groupHook: ExploreGroupHook;
  filterHook: FilterHook;
}

export const ExplorePage = ({ pageName, groupHook, filterHook }: ExplorePageProps) => {
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const {
    groups,
    loadAllSentences,
    loadingStatuses,
    loadMore,
    currentGroup,
    setCurrentGroup,
    replaceOrAddToSearchGroups,
    updateProgress,
    discardGroup,
    copyGroupLink,
    editTitle,
    hasParents,
    togglePinGroup,
    assignChild,
    assignChildren,
    loadListView,
  } = groupHook;

  const [searchPreview, previewDispatch] = useReducer(searchPreviewReducer, {
    group: defaultGroup,
    belongingIds: [],
    excludedIds: [],
    searchTerm: '',
    searchInput: '',
    excludeFromNewFilter: false,
  } as SearchPreviewReducerResult);

  const dispatch: React.Dispatch<TaxonomyAction> = useContext(TaxonomyDispatchContext);
  const taxonomy = useContext(TaxonomyContext);

  const [paginating, setPaginating] = useState(false);
  const [view, setView] = useState<ExplorePageView>(ExplorePageView.Taxonomy);
  const { ref, inView } = useInView({ threshold: 0 });
  const [announcementId, setAnnouncementId] = useState<string | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<string | null>(null);
  const location = useLocation();
  const [filteredGroupCount, setFilteredGroupCount] = useState<number>(0);

  const [getAmountOfGroups, amountOfGroups] = useAmountOfGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: teamId, status: Group_Status.Monitored },
  });
  const [groupSearch, { loading: searchLoading }] = useGroupSearchLazyQuery({
    variables: { teamId, filterInput: filterHook.filters ?? {}, searchString: searchPreview.searchTerm },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getAmountOfGroups();
  }, [teamId]);

  useEffect(() => {
    if (location.pathname === AppRoutes.v3FullPath.explore) {
      const savedPosition = sessionStorage.getItem('savedScrollPosition');
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('savedScrollPosition');
      }
    }
  }, [location]);

  /**
   * This hook will fetch taxonomy with the filters applied, then dispatch the new taxonomy to the reducer.
   * The hook will also re-fetch when the filters change.
   */
  const [loadTaxonomy, { loading: taxonomyLoading }] = useTeamGroupsTaxonomyLazyQuery({
    variables: { teamId, filterInput: filterHook.filters ?? {}, take: pageSize, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'loadTaxonomy',
        payload: data,
      };
      dispatch(action);
      setFilteredGroupCount(data.teamGroups?.amountOfGroups.amount ?? 0);
      // This event is used to track the total page load time (including how long it takes to fetch the taxonomy)
      const event = getTotalPageLoadEvent({ view: 'taxonomy' });
      window.dispatchEvent(event);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  useEffect(() => {
    if (view === ExplorePageView.Flat && inView) {
      // loads groups for the list view of the explore page
      // note that the taxonomy view uses a different state variable (taxonomy)
      loadMoreGroups();
    }
  }, [view, inView, teamId]);

  useEffect(() => {
    switch (view) {
      case ExplorePageView.Flat:
        loadListView(teamId, filterHook.filters);
        break;
      case ExplorePageView.Taxonomy:
        loadTaxonomy({
          variables: {
            teamId,
            filterInput: filterHook.filters ?? {},
            take: pageSize,
            skip: 0,
          },
        });
        break;
      case ExplorePageView.Search: {
        if (searchPreview.searchTerm === '') return;
        groupSearch({
          variables: { teamId, searchString: searchPreview.searchTerm, filterInput: filterHook.filters },
          onCompleted: (data) => {
            const taxonomy = toTaxonomyMap(data.groupSearch as TaxonomyTreeNode[]);
            dispatch({
              type: 'setTaxonomy',
              payload: { taxonomy },
            });
          },
          onError: (error: Error) => {
            toast('Error loading taxonomy', { icon: 'error' });
            console.error(error);
          },
        });
        break;
      }
      default:
        throw new Error('View type not supported');
    }
  }, [view, teamId, filterHook.filters, searchPreview.searchTerm]);

  const loading = searchLoading || taxonomyLoading;

  const loadMoreGroups = async () => {
    setPaginating(true);
    await loadMore(groups.length, pageSize);
    setPaginating(false);
  };
  const navigate = useNavigate();

  return (
    <SearchPreviewContext.Provider value={searchPreview}>
      <SearchPreviewDispatchContext.Provider value={previewDispatch}>
        {announcementId != null && (
          <AnnouncementModal
            groupId={announcementId}
            modalOpen={true}
            setModalOpen={() => setAnnouncementId(undefined)}
            teamId={teamId}
            orgId={orgId}
            orgName={currentOrg?.name}
            filterInput={filterHook.filters}
          />
        )}

        {curGroupIdToDelete !== null ? (
          <GroupDeleter
            groupToDelete={curGroupIdToDelete}
            closeCallback={() => {
              setCurGroupIdToDelete(null);
            }}
            deleteGroup={discardGroup}
            deleteCallback={() => {
              setCurrentGroup(undefined);
              navigate('/dashboard/explore', { replace: true });
            }}
            loadingDelete={loadingStatuses.discardingGroup}
          />
        ) : null}

        <PageWrapper title={'Explore'} styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
          <div>
            <div className={`flex flex-col items-center justify-center`}>
              <div className="flex w-4/5 flex-col gap-y-1">
                <SearchSection setView={setView} filterInput={filterHook.filters} />
                <SearchPreviewModal setView={setView} />
              </div>
            </div>
          </div>

          <div className="flex cursor-default relative flex-col text-blueberry mb-2">
            <div className="grid grid-cols-3 items-center">
              <h1 className="text-3xl font-semibold col-start-1 justify-self-start">Groups</h1>
              <EmployeeOnlyComponent>
                <div className="col-start-2 justify-self-center">
                  <TaxonomyToggle view={view} setView={(view: ExplorePageView) => setView(view)} />
                </div>
              </EmployeeOnlyComponent>
              <div className="col-start-3" />
            </div>
            <div className="absolute -bottom-4">
              <GroupCount
                shouldDisplay={!loading && view !== ExplorePageView.Search}
                filteredGroupCount={filteredGroupCount}
                amountOfGroups={amountOfGroups.data?.amountOfGroups.amount}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-col items-center justify-center">
            <div className="w-4/5 border-b border-gray-300 pb-2 flex flex-row justify-between items-start">
              <FilterManager
                filterHook={filterHook}
                pageName={pageName}
                dataTypeToFilter={'group'}
                queryStringAppliesToGroupTitle={true}
                displayMode={FilterManagerDisplayMode.ExplorePage}
              />
              <div className=" flex flex-row items-center gap-x-2 justify-end">
                <TrendingNewButton filterHook={filterHook} />
                {(user?.isUnwrapper || hasParents) && view === ExplorePageView.Taxonomy ? <TaxonomyChildrenToggle /> : null}
              </div>
            </div>
            <div className={`mt-2 ${view === ExplorePageView.Flat ? 'flex' : ''} w-4/5 flex-col items-center gap-y-4 pt-2`}>
              {view === ExplorePageView.Flat ? (
                <div className="mt-2 flex w-full flex-col items-center gap-y-4 pt-2">
                  {!paginating && loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {groups.length === 0 ? (
                        <div className="my-8 text-blueberry">
                          <h1>No search groups match the selected filters.</h1>
                        </div>
                      ) : (
                        <>
                          {groups.map((group, index) => {
                            const show = index === groups.length - 2;
                            return (
                              // This is the list view?? Can we kill this shit?
                              <GroupPreview
                                refProp={show ? ref : undefined}
                                isCondensedView={false}
                                key={group.id}
                                group={group as GroupFull}
                                filterInput={filterHook.filters}
                                page={pageName}
                                discardSearchGroup={() => {
                                  setCurGroupIdToDelete(group.id);
                                }}
                                togglePinGroup={togglePinGroup}
                                replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                                getAllGroupSentences={() => loadAllSentences(group.id)}
                                loadingAllSentences={loadingStatuses.loadingAllSentences}
                                updateProgress={updateProgress}
                                copyLink={(groupId) => {
                                  const link = copyGroupLink(groupId, filterHook.filters);
                                  return link;
                                }}
                                onSeeMoreClick={() => {
                                  if (currentGroup?.id !== group.id) {
                                    setCurrentGroup(group);
                                  }
                                  navigate(getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.explore));
                                }}
                                editTitle={editTitle}
                                openAnnouncementModal={() => setAnnouncementId(group.id)}
                              />
                            );
                          })}
                          {loadingStatuses.fetchingMoreGroups && (
                            <div>
                              <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : view === ExplorePageView.Taxonomy ? (
                loading ? (
                  <LoadingSpinner />
                ) : (
                  <TaxonomyView
                    replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                    pageName={pageName}
                    currentGroup={currentGroup}
                    filterInput={filterHook.filters}
                    discardSearchGroup={(groupId) => {
                      setCurGroupIdToDelete(groupId);
                    }}
                    togglePinGroup={togglePinGroup}
                    loadingStatuses={loadingStatuses}
                    copyGroupLink={(groupId) => copyGroupLink(groupId, filterHook.filters)}
                    setCurrentGroup={setCurrentGroup}
                    //@ts-ignore
                    openAnnouncementModal={(id) => setAnnouncementId(id)}
                    assignChild={assignChild}
                    assignChildren={assignChildren}
                    discardGroup={discardGroup}
                    updateProgress={updateProgress}
                  />
                )
              ) : view === ExplorePageView.Search ? (
                loading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <GroupSemanticSearchResults
                      replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                      filterInput={filterHook.filters}
                      togglePinGroup={togglePinGroup}
                      copyGroupLink={(groupId) => copyGroupLink(groupId, filterHook.filters)}
                      updateProgress={updateProgress}
                      setCurrentGroupId={(id) => setCurGroupIdToDelete(id)}
                    />
                  </>
                )
              ) : null}
            </div>
          </div>
        </PageWrapper>
      </SearchPreviewDispatchContext.Provider>
    </SearchPreviewContext.Provider>
  );
};
