import { Ancestry, TaxonomyGroup } from '../../v2/hooks/GroupHook';
import {
  AddGroupPayload,
  BuildGroupPayload,
  ExpandState,
  SearchGroupsPayload,
  SetAncestorsPayload,
  SetTaxonomyPayload,
  TaxonomyAction,
  ToggleExpandAllPayload,
  ToggleExpandPayload,
  UpdateGroupTitlePayload,
} from '../actions/taxonomy';
import { TaxonomyTreeNode, TeamGroupsTaxonomyQuery } from '../../generated/graphql';
import { getTaxonomyMap, saveGroupIdsToLocalStorage, toTaxonomyMap } from '../lib/taxonomy';
import toast from 'react-hot-toast';

export const taxonomyReducer = (taxonomy: Map<string, TaxonomyGroup>, action: TaxonomyAction): Map<string, TaxonomyGroup> => {
  switch (action.type) {
    case 'loadTaxonomy': {
      const payload = action.payload as TeamGroupsTaxonomyQuery;
      return toTaxonomyMap(payload.teamGroups?.taxonomyTrees as TaxonomyTreeNode[]);
    }

    case 'updateGroupTitle': {
      return updateGroupTitle(action, taxonomy);
    }

    case 'setTaxonomy': {
      const payload = action.payload as SetTaxonomyPayload;
      return payload.taxonomy;
    }

    case 'updateTaxonomy': {
      return updateTaxonomy(action, taxonomy);
    }

    case 'toggleExpand': {
      return toggleExpand(action, taxonomy);
    }

    case 'toggleExpandAll': {
      return toggleExpandAll(action, taxonomy);
    }

    case 'setAncestors': {
      return setAncestors(action, taxonomy);
    }

    case 'addGroup': {
      return addGroupToTaxonomy(action, taxonomy);
    }
    case 'error': {
      toast.error('Failed to fetch taxonomy from the server. Please try again later.');
      return taxonomy;
    }
    default:
      return taxonomy;
  }
};

const updateGroupTitle = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as UpdateGroupTitlePayload;
  const groupId = payload.groupId;
  const currentTaxonomy = new Map(taxonomy);
  const group = currentTaxonomy.get(groupId);
  if (group) {
    group.title = payload.title;
  }
  return currentTaxonomy;
};

const setAncestors = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as SetAncestorsPayload;
  const currentTaxonomy = new Map(taxonomy);
  const group = currentTaxonomy.get(payload.groupId);
  if (group) {
    group.ancestors = payload.ancestors as Ancestry[];
  }
  return currentTaxonomy;
};
const toggleExpandAll = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as ToggleExpandAllPayload;
  const showChildren = payload.state === ExpandState.Expanded;
  const updatedTaxonomy = new Map(taxonomy);
  // Setting showChildren to true to all groups
  for (let group of Array.from(updatedTaxonomy.values())) {
    group.showChildren = showChildren;
  }

  if (showChildren) {
    // Save all Ids to localstorage
    const allIds = Array.from(updatedTaxonomy.values()).map((group) => group.id);
    saveGroupIdsToLocalStorage(allIds);
    return updatedTaxonomy;
  } else {
    // Remove all Ids from localstorage
    saveGroupIdsToLocalStorage([]);
    return updatedTaxonomy;
  }
};

const toggleExpand = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as ToggleExpandPayload;
  const groupId = payload.groupId;
  const showChildren = payload.state === ExpandState.Expanded;

  if (taxonomy.get(groupId)) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(groupId, {
      ...taxonomy.get(groupId)!,
      showChildren,
    });
    const openIds = [...Array.from(updatedTaxonomy.values())].filter((group) => group.showChildren).map((group) => group.id);
    saveGroupIdsToLocalStorage(openIds);

    return updatedTaxonomy;
  }

  return taxonomy;
};

function updateTaxonomy(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as TeamGroupsTaxonomyQuery;

  return new Map([...Array.from(taxonomy), ...Array.from(toTaxonomyMap(payload.teamGroups?.taxonomyTrees as TaxonomyTreeNode[]))]);
}

function addGroupToTaxonomy(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as AddGroupPayload;
  const updatedTaxonomy = getTaxonomyMap([payload.group], taxonomy);
  return updatedTaxonomy;
}
