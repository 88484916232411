import { FilterInput, GroupFilter, SentimentType, TagFilter, OwnerInput, GroupExternalTicketStatus } from '../../generated/graphql';
import { getFiltersFromUrl, useIsMount } from '../../v2/util';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { FilterCategory, IFilter } from '../sections/Filters/FiltersTypes';

interface FilterHookProps {
  teamId: number;
  orgId: number;
  startingFilterInput?: FilterInput;
  disableUrlFilters?: boolean;
  filterKey?: 'group' | 'chart';
  disableGroupFilterFromUrl?: boolean;
  respectUrlDatesOverStartingFilterInput?: boolean;
}

export interface FilterHook {
  filters: FilterInput;
  setFilters: (filters: FilterInput) => void;
  originalStartDate: Date;
  updateFilterDates: (newStartDate: Date | undefined, newEndDate: Date | undefined, queryStringAppliesToGroupTitle?: boolean) => void;
  filtersFromUrl?: FilterInput;
  disableUrlFilters?: boolean;
}

export const defaultStartDate = moment().subtract(90, 'days').startOf('day').toDate();
export const defaultEndDate = moment().endOf('day').toDate();

/** 
* This hook is used to manage filters. 
* Should get instantiated at the page level, then passed down as props to the FilterManager, as well as to any
component that interacts with filters (date pickers, etc).
*/

export const useFilterHook = ({
  teamId,
  orgId,
  startingFilterInput,
  disableUrlFilters,
  filterKey = 'group',
  disableGroupFilterFromUrl,
  respectUrlDatesOverStartingFilterInput = false,
}: FilterHookProps): FilterHook => {
  const [filtersFromUrl] = useState(getFiltersFromUrl(filterKey, teamId));

  const [originalStartDate] = useState<Date>(defaultStartDate);

  const [filters, setFilters] = useState<FilterInput>(() => {
    const baseFilters = startingFilterInput ?? {
      ...filtersFromUrl,
      startDate: filtersFromUrl && filtersFromUrl.startDate ? moment(filtersFromUrl.startDate).startOf('day').toDate() : defaultStartDate,
      endDate: filtersFromUrl && filtersFromUrl.endDate ? moment(filtersFromUrl.endDate).endOf('day').toDate() : defaultEndDate,
      groupFilter: disableGroupFilterFromUrl ? undefined : filtersFromUrl?.groupFilter,
    };
    // If respectUrlDates is true, use dates from URL if available
    if (startingFilterInput && respectUrlDatesOverStartingFilterInput && filtersFromUrl) {
      return {
        ...baseFilters,
        startDate: filtersFromUrl.startDate ? moment(filtersFromUrl.startDate).startOf('day').toDate() : defaultStartDate,
        endDate: filtersFromUrl.endDate ? moment(filtersFromUrl.endDate).endOf('day').toDate() : defaultEndDate,
      };
    }

    // Otherwise, use dates from startingFilterInput or defaults
    return baseFilters;
  });


  const isFirstRender = useIsMount();

  useEffect(() => {
    //reset filters when changing to another team or changing starting input
    if (!isFirstRender && !!!startingFilterInput) {
      setFilters({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      });
    }
  }, [teamId]);

  const updateFilterDates = (newStartDate: Date = filters.startDate, newEndDate: Date = filters.endDate, queryStringAppliesToGroupTitle?: boolean) => {
    const updatedFilters = {
      ...filters,
      startDate: moment(newStartDate).startOf('day').toDate(),
      endDate: moment(newEndDate).endOf('day').toDate(),
    };

    if (queryStringAppliesToGroupTitle && filters.groupTitleFilterQuery) {
      updatedFilters.groupTitleFilterQuery = filters.groupTitleFilterQuery;
      updatedFilters.queryString = undefined;
    } else {
      updatedFilters.queryString = filters.queryString;
      updatedFilters.groupTitleFilterQuery = undefined;
    }

    setFilters(updatedFilters);

    if (!disableUrlFilters) {
      updateUrlWithFilters(updatedFilters, teamId, orgId, filterKey);
    }
  };

  return {
    filters,
    setFilters,
    originalStartDate,
    updateFilterDates,
    filtersFromUrl,
    disableUrlFilters: !!disableUrlFilters,
  };
};

// New helper function to update URL
const updateUrlWithFilters = (filters: FilterInput, teamId: number, orgId: number, filterKey: string) => {
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set(filterKey, encodeURIComponent(JSON.stringify(filters)));

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const updateFilters = (
  teamId: number,
  orgId: number,
  uiFilters: IFilter[],
  setFilters: (filters: FilterInput) => void,
  startDate?: Date,
  endDate?: Date,
  queryString?: string,
  urlKey?: string,
  queryStringAppliesToGroupTitle?: boolean,
  disableUrlFilters?: boolean
) => {
  const { urlParams, filters } = generateFilterString(teamId, orgId, uiFilters, startDate, endDate, queryString, urlKey, queryStringAppliesToGroupTitle);
  if (!disableUrlFilters) {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }

  setFilters(filters);
};
/**
 * extracted from updateFilters to be used in UI tests
 * @param teamId
 * @param orgId
 * @param uiFilters
 * @param startDate
 * @param endDate
 * @param queryString
 * @param urlKey
 * @param queryStringAppliesToGroupTitle
 * @param path
 * @returns
 */
export const generateFilterString = (
  teamId: number,
  orgId: number,
  uiFilters: IFilter[],
  startDate?: Date,
  endDate?: Date,
  queryString?: string,
  urlKey?: string,
  queryStringAppliesToGroupTitle?: boolean,
  path?: string
) => {
  const filters: FilterInput = {
    segmentFilters: [],
    sentimentFilter: [],
    sourceFitler: [],
    groupFilter: [],
    tagFilters: [],
    ownerFilter: [],
    startDate: startDate,
    endDate: endDate,
    queryString: queryStringAppliesToGroupTitle ? undefined : queryString ? [queryString] : undefined,
    groupTitleFilterQuery: queryStringAppliesToGroupTitle ? queryString : undefined,
    minStarsFilter: [],
    maxStarsFilter: [],
    entryGroupedStatusFilter: [],
    sourceUrlFilter: [],
    groupExternalTicketsFilter: [],
  };
  uiFilters.forEach((uiFilter) => {
    switch (uiFilter.filterCategory) {
      case FilterCategory.Segment:
        filters.segmentFilters?.push({
          filterCondition: uiFilter.filterCondition,
          groupId: uiFilter.filterCategoryId!,
          segments: uiFilter.values.map((value) => value.segment!),
        });
        break;
      case FilterCategory.Sentiment:
        filters.sentimentFilter?.push({
          filterCondition: uiFilter.filterCondition,
          sentiments: uiFilter.values.map((value) => value.sentiment as SentimentType),
        });
        break;
      case FilterCategory.Source:
        filters.sourceFitler?.push({
          filterCondition: uiFilter.filterCondition,
          sources: uiFilter.values.map((value) => value.source!),
        });
        break;
      case FilterCategory.GroupType:
        filters.groupFilter?.push({
          filterCondition: uiFilter.filterCondition,
          group: uiFilter.values.map((value) => ({ type: value.type } as GroupFilter)),
        });
        break;
      case FilterCategory.GroupTitle:
        filters.groupFilter?.push({
          filterCondition: uiFilter.filterCondition,
          group: uiFilter.values.map((value) => ({ id: value.id } as GroupFilter)),
        });
        break;
      case FilterCategory.Tag:
        filters.tagFilters?.push({
          filterCondition: uiFilter.filterCondition,
          tags: uiFilter.values.map((value) => ({ id: value.id } as TagFilter)),
        });
        break;
      case FilterCategory.Owner:
        filters.ownerFilter?.push({
          filterCondition: uiFilter.filterCondition,
          owner: uiFilter.values.map((value) => ({ user_id: value.id } as OwnerInput)),
        });
        break;
      case FilterCategory.MinStars:
        filters.minStarsFilter?.push({
          filterCondition: uiFilter.filterCondition,
          amounts: uiFilter.values.map((value) => value.id!),
        });
        break;
      case FilterCategory.MaxStars:
        filters.maxStarsFilter?.push({
          filterCondition: uiFilter.filterCondition,
          amounts: uiFilter.values.map((value) => value.id!),
        });
        break;
      case FilterCategory.EntryGroupedStatus:
        filters.entryGroupedStatusFilter?.push({
          filterCondition: uiFilter.filterCondition,
          grouped: uiFilter.values.map((value) => Boolean(value.id!)),
        });
        break;
      case FilterCategory.SourceUrl:
        filters.sourceUrlFilter?.push({
          filterCondition: uiFilter.filterCondition,
          urls: uiFilter.values.map((value) => value.title!),
        });
        break;
      case FilterCategory.GroupExternalTicketsStatus:
        filters.groupExternalTicketsFilter?.push({
          filterCondition: uiFilter.filterCondition,
          status: uiFilter.values.map((value) => value.externalTicketStatus as GroupExternalTicketStatus),
        });
        break;
    }
  });
  const url = new URL(path ? path : window.location.href); // window.location.href is not available in tests, must set path manually
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set(urlKey ?? 'filters', encodeURIComponent(JSON.stringify(filters)));
  if (filters.clusterFilters?.length != null && filters.clusterFilters?.length > 0) {
    urlParams.set('collapsed', 'charts');
  }
  return { urlParams, filters };
};

const clusterToGroupFilter = (filter: FilterInput) => {
  if (filter.clusterFilters) {
    return {
      groupFilter: filter.clusterFilters.map((clusterFilter) => {
        return {
          filterCondition: clusterFilter.filterCondition,
          group: clusterFilter.clusters.map((clusterFilterInd) => {
            return { id: clusterFilterInd.id };
          }),
        };
      }),
    };
  }
};
