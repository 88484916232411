import { ProgressState, Status } from '../../../../exports/ProgressMonitor';
import { GroupFull, TaxonomyGroup } from '../../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../../hooks/ExploreGroupHook';
import { FilterHook } from '../../../hooks/FilterHook';
import { HomeGroupHook } from '../../../hooks/HomeGroupHook';
import { ToolbarLoadingSkeleton } from './ToolbarLoadingSkeleton';
import React, { useState } from 'react';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../../../baseComponents/SettingsMenu';
import { exportGroupWithProgressUpdate } from '../../../../eventHandlers/exports/groupExport';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import { Cog6ToothIcon, ArrowDownTrayIcon, TrashIcon, EllipsisHorizontalCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { ToolbarBaseButton } from './buttons/ToolbarBaseButton';
import { TagsPopover } from './TagsPopover';
import { PinGroupButton } from './buttons/PinGroupButton';
import { ReplyToUsersButton } from './buttons/ReplyToUsersButton';
import { GroupSubscriptionsButton } from './buttons/GroupSubscriptionsButton';
import { EditTaxonomyButton } from './buttons/EditTaxonomyButton';
import { CopyGroupUrlButton } from './buttons/CopyGroupUrlButton';
import { EditDigestButton } from './buttons/EditDigestButton';
import LoadingSpinner from '../../../baseComponents/LoadingSpinner';
import { SmallSpinner } from '../../SmallSpinner';
import { createExportSettingsItem } from '../../../baseComponents/SettingsMenuExportItem';

interface GroupToolbarProps {
  group?: GroupFull;
  filterHook: FilterHook;
  groupHook: ExploreGroupHook | HomeGroupHook;
  taxonomy: Map<string, TaxonomyGroup>;
  showDigestButton: boolean;
  toggleDigestModal: () => void;
  toggleDeleteGroupModal: () => void;
}

export const GroupToolbar = ({ group, filterHook, groupHook, taxonomy, showDigestButton, toggleDigestModal, toggleDeleteGroupModal }: GroupToolbarProps) => {
  return (
    <div className="flex flex-row w-full">
      {!group ? (
        <ToolbarLoadingSkeleton />
      ) : (
        <div className="flex flex-row w-full pb-2 lg:pb-0 lg:justify-end gap-x-1 lg:gap-x-4">
          <TagsPopover group={group} groupHook={groupHook} />
          <ButtonGroup>
            <PinGroupButton group={group} groupHook={groupHook} buttonRounding="left" />
            <GroupSubscriptionsButton group={group} buttonRounding="right" />
          </ButtonGroup>
          <ButtonGroup>
            <ReplyToUsersButton group={group} buttonRounding="left" />
            <EditTaxonomyButton group={group} groupHook={groupHook} filters={filterHook.filters} taxonomy={taxonomy} />
            <CopyGroupUrlButton group={group} filters={filterHook.filters} buttonRounding="right" />
          </ButtonGroup>
          {showDigestButton ? <EditDigestButton onClick={toggleDigestModal} buttonRounding="full" /> : null}
          <MoreSettingsButton group={group} filterHook={filterHook} toggleDeleteGroupModal={toggleDeleteGroupModal} />
        </div>
      )}
    </div>
  );
};

interface MoreSettingsProps {
  group: GroupFull;
  filterHook: FilterHook;
  toggleDeleteGroupModal: () => void;
}

const MoreSettingsButton = ({ group, filterHook, toggleDeleteGroupModal }: MoreSettingsProps) => {
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });

  const { curTeamId } = useValidTeamAppContext();
  const settings: ISettingsItem[] = [
    {
      ...createExportSettingsItem({ id: 0, group, curTeamId, filterHook, exportProgress, updateExportProgress }),
    },
    {
      name: 'Delete Group',
      textColor: 'failure',
      id: 1,
      group: 'delete',
      htmlId: 'delete-group',
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => toggleDeleteGroupModal(),
    },
  ];

  return (
    <SettingsMenu settings={settings} center={true} align={MenuAlign.RIGHT}>
      <ToolbarBaseButton
        id="toolbar-group-settings-icon"
        icon={<EllipsisVerticalIcon className="h-5 w-5 stroke-2" />}
        buttonRounding="full"
        label="More Options"
      />
    </SettingsMenu>
  );
};

export const ButtonGroup = ({ children }: { children: JSX.Element[] }) => {
  return <div className="flex flex-row divide-x divide-gray-300">{children.map((child) => React.cloneElement(child))}</div>;
};
