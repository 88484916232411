import { ApolloQueryResult } from '@apollo/client';
import { client } from '../../client';
import { FilterInput, FilterType, GetPartialEntriesQuery, PartialEntryFragment, GetPartialEntriesDocument } from '../../generated/graphql';
import { PaginatedQuery, PaginationParams } from './PaginatedQuery';

export class GetEntriesInPages implements PaginatedQuery<ApolloQueryResult<GetPartialEntriesQuery>> {
  private teamId: number;
  private filterInput: FilterInput;
  private groupIds: string[];

  private entries: any[];

  constructor(params: { teamId: number; filterInput: FilterInput; groupIds: string[] }) {
    this.teamId = params.teamId;
    this.filterInput = params.filterInput;
    this.groupIds = params.groupIds;

    this.entries = [];
  }

  getEntries(): PartialEntryFragment[] {
    return this.entries;
  }

  onResolve(response: ApolloQueryResult<GetPartialEntriesQuery>): void {
    let entriesResponse = response.data.entries ?? [];
    this.entries.push(...entriesResponse);
  }

  fetch(params: PaginationParams): Promise<ApolloQueryResult<GetPartialEntriesQuery>> {
    const teamId = this.teamId;
    const filterInput: FilterInput = this.getFilterInput();

    return client.query<GetPartialEntriesQuery>({
      fetchPolicy: 'no-cache',
      query: GetPartialEntriesDocument,
      variables: {
        teamId,
        filterInput: filterInput ?? {},
        take: params.take,
        skip: params.skip,
      },
    });
  }

  private getFilterInput(): FilterInput {
    const groupFilter =
      this.groupIds.length > 0
        ? {
            groupFilter: [
              {
                filterCondition: FilterType.Or,
                group: this.groupIds.map((groupId: string) => ({ id: groupId })),
              },
            ],
          }
        : {};

    return { ...this.filterInput, ...groupFilter };
  }
}
