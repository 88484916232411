import { FeedbackEntriesQuery } from '../../generated/graphql';
import { FeedbackEntryCard } from './FeedbackEntryCard';
interface FeedbackEntriesListProps {
  entries: FeedbackEntriesQuery['entries'];
  validIntegrations: string[];
}

export const FeedbackEntriesList = ({ entries, validIntegrations }: FeedbackEntriesListProps) => {
  return (
    <div>
      <ul role="list" className="flex w-full flex-col gap-y-3">
        {entries?.map((entry) => {
          const showConversation = validIntegrations.includes(entry.source ?? '');
          return (
            <li key={entry.id}>
              <FeedbackEntryCard entry={entry} compact={showConversation ? false : entry.hasConversation} allowReplyExpansion={showConversation} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
