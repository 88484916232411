import { useContext, useEffect, useState } from 'react';
import { DigestDataContext, DigestDataDispatchContext } from '../../../context/digestDataContext';
import { CreateDigestProps } from './CreateDigestSeries';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { GetDigestsDocument, GetDigestsQuery, useCreateDigestMutation } from '../../../generated/graphql';
import { DigestDataActions } from '../../../reducers/digests/digestDataReducer';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Modal from '../../baseComponents/Modal';
import { Dialog } from '@headlessui/react';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import Form from '../../../baseComponents/Form';
import { EMPTY_DROPDOWN_ITEM } from '../../../reducers/utilities/consts';

const CreateDigest = ({ modalOpen, setFalseToCloseModal }: CreateDigestProps) => {
  const digestData = useContext(DigestDataContext);
  const [selectedDigestSeries, setSelectedDigestSeries] = useState<IDropDownItem>(EMPTY_DROPDOWN_ITEM);
  const [createDigest, digest] = useCreateDigestMutation();

  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setFalseToCloseModal(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={selectedDigestSeries.id === EMPTY_DROPDOWN_ITEM.id || digest.loading}
            onClick={() => {
              createDigest({
                variables: {
                  digestSeriesId: Number(selectedDigestSeries.id),
                },
                refetchQueries: [{ query: GetDigestsDocument, variables: {} }],
                awaitRefetchQueries: true,
                onCompleted: () => {
                  setFalseToCloseModal(false);
                },
              });
            }}
            loadingConfirm={digest.loading}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={setFalseToCloseModal}>
      <div className="mt-3 text-center sm:mt-5 text-blueberry">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Create New Digest
        </Dialog.Title>
        <div className="grid grid-cols-3 gap-x-4 mt-8 gap-y-8">
          {/* Digest Series Selection combo box */}
          <div className="col-span-1 flex items-center justify-end">
            <h1 className=" text-lg font-semibold">Digest Series</h1>
          </div>
          <div className="col-span-2 pr-6">
            <VirtualizedComboBox
              placeholder="Choose Digest Series..."
              comboBoxData={digestData.digestSeriesData.map((series) => {
                return {
                  name: `${series.title} - ${series.organization.name} (${series.organization.id})`,
                  id: series.id,
                };
              })}
              setSelectedItem={(item) => {
                if (item) {
                  setSelectedDigestSeries(item);
                } else {
                  setSelectedDigestSeries(EMPTY_DROPDOWN_ITEM);
                }
              }}
              selectedItem={selectedDigestSeries}
            />
          </div>
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
};

export default CreateDigest;
