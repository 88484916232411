import { useUnlinkAsanaTicketMutation, useUnlinkJiraTicketMutation } from '../../generated/graphql';
import { ExternalTicketsScraperKeys } from '../components/linkedActions/TicketBrowsing';

export const useUnlinkExternalTicketHook = ({ orgId }: { orgId: number }) => {
  const [unlinkJira] = useUnlinkJiraTicketMutation({});
  const [unlinkAsana] = useUnlinkAsanaTicketMutation({});

  const ScraperKeyToMutationMap: Record<string, typeof unlinkJira | typeof unlinkAsana> = {
    [ExternalTicketsScraperKeys.jira]: unlinkJira,
    [ExternalTicketsScraperKeys.asana]: unlinkAsana,
  };

  const unlinkTicketFromGroup = async ({ groupId, ticketId, scraperKey }: { groupId: string; ticketId: number; scraperKey: string }) => {
    const unlinkMutation = ScraperKeyToMutationMap[scraperKey];
    await unlinkMutation({
      variables: {
        groupId,
        externalTicketId: ticketId,
        orgId,
      },
    });
  };

  return { unlinkTicketFromGroup };
};
