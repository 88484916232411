import { GroupFull, TaxonomyGroup } from '../../../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../../../hooks/HomeGroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { Fragment } from 'react';
import { Icon } from '@iconify/react';
import { Popover, Transition } from '@headlessui/react';
import GroupTaxonomyEdit from '../../../GroupTaxonomyEdit';
import { FilterInput } from '../../../../../generated/graphql';

export function EditTaxonomyButton(props: {
  group: GroupFull;
  groupHook: ExploreGroupHook | HomeGroupHook;
  filters: FilterInput;
  taxonomy: Map<string, TaxonomyGroup>;
  buttonRounding?: 'left' | 'right';
}) {
  const openTaxonomyOnLoad = window.location.search.includes('taxonomy=true');

  const { group, groupHook, filters, taxonomy } = props;

  return (
    <Popover className="relative">
      <>
        <Popover.Button className="rounded-full bg-silver text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none">
          <ToolbarBaseButton
            id="toolbar-edit-taxonomy-button"
            icon={<Icon icon="mdi:file-tree" color={'#292e5b'} width="20" height="20" className="cursor-pointer" />}
            label={'Configure Taxonomy'}
            buttonRounding={props.buttonRounding}
          />{' '}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 px-8 z-50 flex">
            {!!group && (
              <GroupTaxonomyEdit
                groupId={group?.id}
                parentId={group.id && taxonomy ? taxonomy.get(group.id)?.parentId : undefined}
                filterInput={filters}
                loadingStatuses={groupHook.loadingStatuses}
                assignChild={groupHook.assignChild}
                assignChildren={groupHook.assignChildren}
                deleteChild={groupHook.deleteChild}
                childCandidates={groupHook.childCandidates}
                getChildCandidates={groupHook.getChildCandidates}
                children={groupHook.children}
                getChildren={groupHook.getChildren}
                removeChildFromParent={groupHook.removeChildFromParent}
                childrenToAssign={groupHook.childrenToAssign}
                getChildrenToAssign={groupHook.getPotentialChildren}
                taxonomy={taxonomy}
              />
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}
