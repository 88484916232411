import { BubbleDataPoint, ChartData, ChartTypeRegistry, ScatterDataPoint, TooltipItem } from 'chart.js';
import { Chart_Type } from '../../../generated/graphql';
import { YAxisUnit } from '../../../v2/hooks/ChartHook';

/**
 * Returns a label name for the tooltip in the charts.
 *
 * For Horizontal Bar Chart and Pie Chart the legend shows the label name in the title and is handled separately.
 *
 * For other chart types, the label name is shown in the body of the tooltip and comes from here.
 */
function getLabelNameForTooltip(
  data: ChartData<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>,
  datasetIndex: number,
  chartType: Chart_Type
): string {
  if (chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar) {
    return '';
  }

  const label = data.datasets[datasetIndex].label;
  return label ? `${label} - ` : '';
}

interface TooltipStrategy {
  getTitle(tooltipItem: TooltipItem<'bar' | 'line'>[], fullLabels: string[], chart_type: Chart_Type): string;
  getLabel(data: TooltipItem<'bar' | 'line'>, chart_type: Chart_Type): string;
}

class PercentageStrategy implements TooltipStrategy {
  getTitle(tooltipItem: TooltipItem<'bar' | 'line'>[], fullLabels: string[], chart_type: Chart_Type): string {
    if (chart_type === Chart_Type.Pie || chart_type === Chart_Type.HorizontalBar) {
      return tooltipItem.length > 0 ? fullLabels[tooltipItem[0].dataIndex] : '';
    }
    return tooltipItem.length > 0 ? 'Percent of Feedback for ' + fullLabels[tooltipItem[0].dataIndex] : '';
  }

  getLabel(data: TooltipItem<'bar' | 'line'>, chart_type: Chart_Type): string {
    if (Number(data.formattedValue) === 0) return '';
    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
    if (floor === 0) floor = Number(data.formattedValue);
    const labelName = getLabelNameForTooltip(data.chart.data, data.datasetIndex, chart_type);
    if (chart_type === Chart_Type.Pie || chart_type === Chart_Type.HorizontalBar) {
      return floor + '% of feedback';
    }
    return labelName + floor + '%';
  }
}

class FavorabilityStrategy implements TooltipStrategy {
  getTitle(tooltipItem: TooltipItem<'bar' | 'line'>[], fullLabels: string[], chart_type: Chart_Type): string {
    if (chart_type === Chart_Type.Pie || chart_type === Chart_Type.HorizontalBar) {
      return tooltipItem.length > 0 ? fullLabels[tooltipItem[0].dataIndex] : '';
    }
    return tooltipItem.length > 0 ? 'Positive Sentiment Rate for ' + fullLabels[tooltipItem[0].dataIndex] : '';
  }

  getLabel(data: TooltipItem<'bar' | 'line'>, chart_type: Chart_Type): string {
    if (Number(data.formattedValue) === 0) return '';
    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
    if (floor === 0) floor = Number(data.formattedValue);
    const labelName = getLabelNameForTooltip(data.chart.data, data.datasetIndex, chart_type);
    if (chart_type === Chart_Type.Pie || chart_type === Chart_Type.HorizontalBar) {
      return floor + '% positive sentiment rate';
    }
    return labelName + floor + '%';
  }
}

class AbsoluteMentionsStrategy implements TooltipStrategy {
  getTitle(tooltipItem: TooltipItem<'bar' | 'line'>[], fullLabels: string[], chart_type: Chart_Type): string {
    if (chart_type === Chart_Type.Pie || chart_type === Chart_Type.HorizontalBar) {
      return tooltipItem.length > 0 ? fullLabels[tooltipItem[0].dataIndex] : '';
    }
    return tooltipItem.length > 0 ? 'Total Mentions for ' + fullLabels[tooltipItem[0].dataIndex] : '';
  }

  getLabel(data: TooltipItem<'bar' | 'line'>, chart_type: Chart_Type): string {
    if (Number(data.formattedValue) === 0) return '';
    const labelName = getLabelNameForTooltip(data.chart.data, data.datasetIndex, chart_type);
    return labelName + `${data.formattedValue} mentions`;
  }
}

export function getTooltipStrategy(yAxisUnit: YAxisUnit): TooltipStrategy {
  switch (yAxisUnit) {
    case YAxisUnit.CustomPercentage:
      return new PercentageStrategy();
    case YAxisUnit.Favorability:
      return new FavorabilityStrategy();
    case YAxisUnit.CustomAbsolute:
      return new AbsoluteMentionsStrategy();
    default:
      return new AbsoluteMentionsStrategy();
  }
}

// Use this function to get tooltip callbacks
export function getTooltipCallbacks(chart_type: Chart_Type, yAxisUnit: YAxisUnit, fullLabels: string[]) {
  const strategy = getTooltipStrategy(yAxisUnit);
  return {
    title: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => strategy.getTitle(tooltipItem, fullLabels, chart_type),
    label: (data: TooltipItem<'bar' | 'line'>) => strategy.getLabel(data, chart_type),
  };
}
