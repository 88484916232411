import { useState, useEffect, useContext, useReducer } from 'react';

//@ts-ignore
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import DashboardV2 from './v2/DashboardV2';
import ForgotPasswordPage from './v3/pages/auth/ForgotPassword';
import UserContext from './v2/contexts/UserContext';
import AppContext, { AppMode } from './v2/contexts/AppContext';
import GrantAccess from './v2/pages/Auth/GrantAccess';
import GoogleResponse from './v3/pages/auth/GoogleResponse';
import { Hub } from 'aws-amplify';
import PreviewPage from './v3/pages/PreviewPage';
import { AppRoutes, V3_DASHBOARD } from './Routes';

import LoginPage from './v3/pages/auth/LoginPage';
import SignUpPage from './v3/pages/auth/SignupPage';
import SSOResponse from './v3/pages/auth/SSOResponse';
import SSOLogin from './v3/pages/auth/SSOLogin';
import SecretPage from './v3/pages/SecretPage';
import { TaxonomyGroup } from './v2/hooks/GroupHook';
import { taxonomyReducer } from './v3/reducers/taxonomy';
import { TaxonomyContext } from './v3/context/TaxonomyContext';
import { TaxonomyDispatchContext } from './v3/context/TaxonomyDispatchContext';
import SSOSession from './v3/pages/auth/federated/SSOSession';

const __privacyhtml = require('./privacy.html.js');
const privacyHtml = { __html: __privacyhtml };
const __termshtml = require('./terms.html.js');
const termsHtml = { __html: __termshtml };

interface IAuthRouted {
  children: JSX.Element;
}
const AuthedRoute = ({ children }: IAuthRouted) => {
  const { user } = useContext(UserContext);
  if (user) {
    return children;
  } else {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('destination', window.location.pathname);
    return <Navigate to={{ pathname: '/login', search: urlSearchParams.toString() }} />;
  }
};

const App = () => {
  const { setCurrentUser } = useContext(UserContext);
  const { setMode, setCurrentUuid } = useContext(AppContext);
  const [urlQueryParams, setUrlQueryParams] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [taxonomy, dispatch] = useReducer(taxonomyReducer, new Map<string, TaxonomyGroup>());

  //On initial page load, set current user and initial app status

  /**
   * Get query params from auth context event.
   * When signing in with Google we need to pass query params into a customState parameter and fetch them back here.
   * This lets us keep any query parameters that are in the url search path (like invite link hash) and make use of
   * the query parameters once sign in with google is complete.
   */
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'customOAuthState':
        setUrlQueryParams(data);
    }
  });

  useEffect(() => {
    const getCurrentUser = async () => {
      await setCurrentUser();
      setLoading(false);
    };
    getCurrentUser();
    if (window.location.pathname.startsWith('/preview') || window.location.pathname.startsWith('/newPreview')) {
      setMode(AppMode.PreviewMode);
      const urlParams = new URLSearchParams(window.location.search);
      const paramsTeamUuid = urlParams.get('team_id');
      setCurrentUuid(paramsTeamUuid!);
    } else setMode(AppMode.RegularMode);
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div>
      <TaxonomyContext.Provider value={taxonomy}>
        <TaxonomyDispatchContext.Provider value={dispatch}>
          <Router>
            <div>
              <Routes>
                <Route path="/preview" element={<PreviewPage />} />

                <Route
                  path={`${V3_DASHBOARD}/*`}
                  element={
                    <AuthedRoute>
                      <DashboardV2 />
                    </AuthedRoute>
                  }
                />

                <Route path={AppRoutes.login} element={<LoginPage />} />

                <Route path={AppRoutes.grantAccess} element={<GrantAccess />} />

                <Route path={AppRoutes.authGoogle} element={<GoogleResponse urlQueryParams={urlQueryParams} />} />

                {/* We do this because Okta was the first IDP we supported. Future IDPs should use ssoResponse and ssoLogin routes */}
                <Route path={AppRoutes.authOktaResponse} element={<SSOResponse />} />
                <Route path={AppRoutes.loginOkta} element={<SSOLogin />} />

                <Route path={AppRoutes.ssoResponse} element={<SSOResponse />} />
                <Route path={AppRoutes.ssoLogin} element={<SSOLogin />} />
                <Route path={AppRoutes.ssoSession} element={<SSOSession />} />

                <Route path={AppRoutes.signup} element={<SignUpPage />} />

                <Route path={AppRoutes.forgotPassword} element={<ForgotPasswordPage />} />

                <Route path={AppRoutes.privacy} element={<span dangerouslySetInnerHTML={privacyHtml} />} />

                <Route path={AppRoutes.terms} element={<span dangerouslySetInnerHTML={termsHtml} />} />

                <Route path="/*" element={<Navigate to={V3_DASHBOARD} />} />
                <Route
                  path={AppRoutes.secret}
                  element={
                    <AuthedRoute>
                      <SecretPage />
                    </AuthedRoute>
                  }
                />

                <Route element={<Navigate to={AppRoutes.v3FullPath.invalid} />} />
              </Routes>
            </div>
          </Router>
        </TaxonomyDispatchContext.Provider>
      </TaxonomyContext.Provider>
    </div>
  );
};

export default App;
