import { useState } from 'react';
import { GroupDependencies } from '../../generated/graphql';
import DeleteGroupModal from './Modals/DeleteGroupModal';

export const GroupDeleter = ({
  groupToDelete,
  deleteGroup,
  loadingDelete,
  deleteCallback,
  closeCallback,
  hasChildren,
}: {
  groupToDelete: string;
  closeCallback: () => void;
  deleteGroup: (groupId: string, cb?: (() => void) | undefined) => Promise<void | GroupDependencies>;
  deleteCallback?: () => void;
  loadingDelete: boolean;
  hasChildren?: boolean;
}) => {
  const [dependencies, setDependencies] = useState<GroupDependencies | undefined>();
  const confirmDeleteGroup = async () => {
    if (groupToDelete) {
      const deps = await deleteGroup(groupToDelete);
      if (deps) setDependencies(deps);
      else {
        closeCallback();
        deleteCallback?.();
      }
    }
  };
  return (
    <>
      {!!groupToDelete ? (
        <DeleteGroupModal
          modalOpen={!!groupToDelete}
          callbackModal={closeCallback}
          confirmButton={confirmDeleteGroup}
          loadingConfirm={loadingDelete}
          dependencies={dependencies}
        />
      ) : null}
    </>
  );
};
