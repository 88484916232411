import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Form from '../../../baseComponents/Form';
import Modal from '../../baseComponents/Modal';
import AnnouncementEmailForm from './Views/AnnouncementEmailForm';
import AnnouncementEmailPreview from './Views/AnnouncementEmailPreview';
import { FilterInput } from '../../../generated/graphql';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import LogoModal from './LogoModal';
import { useAnnouncement } from '../../../v2/hooks/AnnouncementHook';
import 'tippy.js/themes/light.css';

interface AnnouncementModalProps {
  groupId: string;
  modalOpen: boolean;
  teamId: number;
  orgId: number;
  orgName: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  filterInput?: FilterInput;
}

const AnnouncementModal = ({ groupId, modalOpen, setModalOpen, teamId, orgId, orgName, filterInput }: AnnouncementModalProps): JSX.Element => {
  const [emailFormOpen, setEmailFormOpen] = useState<boolean>(true);
  const [logoModalOpen, setLogoModalOpen] = useState<boolean>(false);
  const {
    emailList,
    subject,
    body,
    warning,
    image,
    setWarning,
    setSubject,
    setBody,
    addImage,
    emailPreview,
    addEmail,
    removeEmail,
    sendResponseEmail,
    emailInProgress,
    createOutboundEmailPreview,
    copyEmailsToClipboard,
  } = useAnnouncement(groupId, teamId, orgId, () => setModalOpen(false), filterInput);

  useEffect(() => {
    if (!emailFormOpen) {
      setWarning(false);
      createOutboundEmailPreview();
    }
  }, [emailFormOpen]);

  const handleNextClick = () => {
    if (emailList.length > 0) setEmailFormOpen(false);
    else setWarning(true);
  };

  const getFormButtons = () => {
    return (
      <div className="mt-8 w-full grid grid-cols-5 justify-end gap-x-20 text-center">
        <div className="col-span-2">
          <Button expandWidth expandHeight variant={ButtonVariant.Tertiary} onClick={() => setModalOpen(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-3 flex flex-row gap-x-4">
          <div className="w-12 flex justify-end items-center">
            {emailFormOpen ? (
              <Tippy theme="dark" content={<p className="text-center">Attach your business logo at the top of your announcement.</p>}>
                <div className="">
                  <PaperClipIcon className="h-8 w-8 cursor-pointer text-blueberry" onClick={() => setLogoModalOpen(true)} />
                </div>
              </Tippy>
            ) : (
              <Button text="Back" variant={ButtonVariant.Cancel} onClick={() => setEmailFormOpen(true)} loadingConfirm={false} className="box-border" />
            )}
          </div>
          <Button
            expandWidth
            expandHeight
            variant={ButtonVariant.Primary}
            text={emailFormOpen ? 'Next' : 'Send Email'}
            onClick={() => (emailFormOpen ? handleNextClick() : sendResponseEmail())}
            loadingConfirm={emailInProgress}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {logoModalOpen && (
        <LogoModal image={image} modalOpen={logoModalOpen} callbackModal={() => setLogoModalOpen(false)} confirmButton={(logoUrl) => addImage(logoUrl)} />
      )}
      <Modal open={modalOpen} setOpen={setModalOpen} width="md:w-[42rem] sm:max-w-2xl">
        <div>
          <Form bottomRow={getFormButtons()}>
            <h1 className="mb-3 text-3xl font-bold text-blueberry">Announce Feature</h1>
            {emailFormOpen ? (
              <AnnouncementEmailForm
                announcementRecipients={emailList}
                warning={warning}
                subject={subject}
                body={body}
                setSubject={setSubject}
                setBody={setBody}
                copyEmailsToClipboard={copyEmailsToClipboard}
                addEmail={addEmail}
                removeEmail={removeEmail}
              />
            ) : (
              <AnnouncementEmailPreview emailPreview={emailPreview} emailPreviewLoading={emailPreview == null} subject={subject} orgName={orgName} />
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AnnouncementModal;
