import { FilterInput, InsightLightFragment, Insight_Collection_Type } from '../../../generated/graphql';
import { Events, logEventWithContext } from '../../../v2/AnalyticsUtil';
import { buildFilterInputFromSavedFilterInput } from '../../pages/ChartsPage';
import InsightSliderCard from './InsightSliderCard';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';

interface InsightSliderViewProps {
  insights: InsightLightFragment[];
  openGroup: (groupId: string, filterInput: FilterInput) => void;
  pageName: string;
}

const InsightSliderView = (props: InsightSliderViewProps) => {
  const { insights } = props;
  return (
    <div data-testid="insight-slider-view">
      <h1 className="text-2xl font-semibold text-blueberry mb-1">Top Insights This Week</h1>
      <div id="carouselRootElement" className="slide carousel carousel-dark relative" data-bs-ride="carousel">
        <div className="carousel-inner relative w-full overflow-hidden h-full ">
          {insights
            .filter((val) => val.collection.type === Insight_Collection_Type.Group)
            .map((insight, index) => {
              const insightFilterInput = insight.filterInput;
              return (
                <div className={`carousel-item ${index === 0 ? 'active' : ''} relative float-left w-full`} key={index} data-bs-interval="10000">
                  <InsightSliderCard
                    insight={insight}
                    onClick={() => {
                      props.openGroup(
                        insight.collection.id,
                        buildFilterInputFromSavedFilterInput(insightFilterInput, insightFilterInput.startDate, insightFilterInput.endDate)
                      );
                      logEventWithContext(Events.InsightCardClicked, {
                        Page: props.pageName,
                        Depth: index,
                      });
                    }}
                  />
                </div>
              );
            })}
        </div>

        {/* Only show the carosel buttons if we have more than one insight. */}
        {insights.length > 1 && (
          <>
            <div className="flex flex-row justify-center pt-3">
              <div className="group px-5">
                <button
                  className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pr-1.5"
                  data-bs-target="#carouselRootElement"
                  data-bs-slide="prev"
                >
                  <ChevronDoubleLeftIcon className="inline-block h-6 w-6 stroke-2" />
                </button>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="carousel-indicators flex justify-center ">
                  {insights.map((_, index) => {
                    return (
                      <button
                        onClick={() =>
                          logEventWithContext(Events.CarouselButtonClicked, {
                            Page: props.pageName,
                            Depth: index,
                          })
                        }
                        key={index}
                        type="button"
                        data-bs-target="#carouselRootElement"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                        aria-current="true"
                        aria-label={`Slide ${index}`}
                      ></button>
                    );
                  })}
                </div>
              </div>
              <div className="group px-5">
                <button
                  className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pl-1.5"
                  data-bs-target="#carouselRootElement"
                  data-bs-slide="next"
                >
                  <ChevronDoubleRightIcon className="inline-block h-6 w-6 stroke-2 " />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InsightSliderView;
