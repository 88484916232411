import { Dispatch } from 'react';
import { EntryFragment } from '../../generated/graphql';

export enum GroupEntriesActions {
  ADD_ENTRY,
  REMOVE_ENTRY,
  APPEND_ENTRIES,
  SET_ENTRIES,
  SET_SIMILAR_ENTRIES,
  APPEND_SIMILAR_ENTRIES,
  SET_SIMILAR_ENTRIES_LOADING,
  SET_ENTRIES_LOADING,
}

export interface GroupEntriesState {
  entries: EntryFragment[];
  entriesLoading: boolean;
  similarEntries: EntryFragment[];
  similarEntriesLoading: boolean;
  /**
   * Both endOfXEntriesReached keep track of reaching the end of our ability to paginate.
   * when set to true we've run out of data to load in.
   *
   * The core assumption is if we call APPEND_X_ENTRIES and there's nothing to append we've reached the end of
   * pagination. This shoud be ok.. :/
   */
  endOfEntriesReached: boolean;
  endOfSimilarEntriesReached: boolean;

  /**
   * Both XSkipAmounts track how far along we are in pagniation progress.
   * Each is effectively the length of the respective array entries or similarEntries
   */
  entriesSkipAmount: number;
  similarEntriesSkipAmount: number;
}

export const GroupEntriesInitialState = (): GroupEntriesState => {
  return {
    entries: [],
    similarEntries: [],
    entriesLoading: true,
    similarEntriesLoading: true,
    endOfEntriesReached: false,
    endOfSimilarEntriesReached: false,
    entriesSkipAmount: 0,
    similarEntriesSkipAmount: 0,
  };
};

interface SetEntries {
  entries: EntryFragment[];
}

interface SetEntry {
  entryId: string;
}

interface NoOp {}

type GroupEntriesActionPayload = SetEntries | SetEntry | NoOp;

export type GroupEntriesAction = {
  type: GroupEntriesActions;
  payload: GroupEntriesActionPayload;
};
export type GroupEntriesDispatch = Dispatch<GroupEntriesAction>;

/**
 * This manages two lists, their loading states, and their pagination progress identically.
 * In fact the only different between the lists is the set of entries they contain, they are infact the same data type...
 *
 * Soooo why not instantiate two separate 'list-managers' and stuff up with the different data
 *
 * well, we could do that but we need to be able to pass entries between lists.
 * Concretely, we need to be able to take an entry from similarEntries and put it in entries.
 * If we did two separately instantiated list managers we'd need someone else to handle the swaparoo.
 *
 * So, maybe this is me being lazy (there's some very very similar looking code) but I figured it'd be easier to manage
 * both the lists in the same spot.
 *
 * @param state
 * @param action
 * @returns
 */
export const GroupEntriesReducer = (state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState => {
  switch (action.type) {
    case GroupEntriesActions.SET_ENTRIES_LOADING:
      return setEntriesLoading(state, action);
    case GroupEntriesActions.SET_SIMILAR_ENTRIES_LOADING:
      return setSimilarEntriesLoading(state, action);
    case GroupEntriesActions.ADD_ENTRY:
      return addEntry(state, action);
    case GroupEntriesActions.APPEND_ENTRIES:
      return appendEntries(state, action);
    case GroupEntriesActions.APPEND_SIMILAR_ENTRIES:
      return appendSimilarEntries(state, action);
    case GroupEntriesActions.REMOVE_ENTRY:
      return removeEntry(state, action);
    case GroupEntriesActions.SET_ENTRIES:
      return setEntries(state, action);
    case GroupEntriesActions.SET_SIMILAR_ENTRIES:
      return setSimilarEntries(state, action);
    default:
      throw new Error(`Cannot handle ${action.type}`);
  }
};

/**
 * adds selected entry to the Group's list of entries.
 * By adding this entry we also want to remove the selected entry from the list of similarEntries
 * @param state
 * @param action
 */
function addEntry(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntry;
  const entryId = payload.entryId;

  if (!entryId) {
    throw new Error('Add Entry expects entryId to be set on the action payload');
  }

  const entryToAdd = state.similarEntries.find((entry) => entry.id === entryId);
  if (!entryToAdd) {
    throw new Error(`Cannot find entry to add to GroupEntries list, missing EntryId: ${entryId}`);
  }

  return {
    ...state,
    entries: [entryToAdd, ...state.entries],
    similarEntries: state.similarEntries.filter((entry) => entry.id !== entryId),
  };
}

/**
 * Appends a list of Entries (fetched from paginating) to the current list of entries
 * @param state
 * @param action
 */
function appendEntries(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntries;
  const entries = payload.entries;
  if (!entries) {
    throw new Error('Append Entries expecs the entries array to be set');
  }

  const newEntries = [...state.entries, ...entries];

  return {
    ...state,
    entries: newEntries,
    entriesSkipAmount: state.entriesSkipAmount + entries.length,
    endOfEntriesReached: entries.length === 0,
  };
}

/**
 * Appends a list of entries to the list of similar entries in the state
 * @param state
 * @param action
 */
function appendSimilarEntries(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntries;
  const entries = payload.entries;
  if (!entries) {
    throw new Error('Append Entries expecs the entries array to be set');
  }

  const newSimilarEntries = [...state.similarEntries, ...entries];

  return {
    ...state,
    similarEntries: newSimilarEntries,
    similarEntriesSkipAmount: state.similarEntriesSkipAmount + entries.length,
    endOfSimilarEntriesReached: entries.length === 0,
  };
}

function removeEntry(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntry;
  const entryId = payload.entryId;
  if (!entryId) {
    throw new Error('Remove Entries expects entryId to be set');
  }

  const entryToRemove = state.entries.find((entry) => entry.id === entryId);
  if (!entryToRemove) {
    throw new Error('Cannot find the entry to remove');
  }

  return {
    ...state,
    entries: state.entries.filter((entry) => entry.id !== entryId),
  };
}

function setEntries(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntries;
  const entries = payload.entries;
  if (!entries) {
    throw new Error('Set Entries expects entries array to be set.');
  }

  return {
    ...state,
    entries,
    entriesLoading: false,
    endOfEntriesReached: false,
    entriesSkipAmount: entries.length,
  };
}

function setSimilarEntries(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  const payload = action.payload as SetEntries;
  const entries = payload.entries;
  if (!entries) {
    throw new Error('Set Similar Entries expects entries array to be set.');
  }

  return {
    ...state,
    similarEntries: entries,
    similarEntriesLoading: false,
    endOfSimilarEntriesReached: false,
    similarEntriesSkipAmount: entries.length,
  };
}

/**
 * Sets the loading state for the similar entries array. Makes the assumption that if your are loading similar entries you must also clear the similar entries array
 * @param state
 * @param action
 * @returns
 */
function setSimilarEntriesLoading(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  return {
    ...state,
    similarEntries: [],
    similarEntriesLoading: true,
  };
}

/**
 * Sets the loading state for the entries array. Makes the assumption that if your are loading entries you must also clear the entries array
 * @param state
 * @param action
 * @returns
 */
function setEntriesLoading(state: GroupEntriesState, action: GroupEntriesAction): GroupEntriesState {
  return {
    ...state,
    entries: [],
    entriesLoading: true,
  };
}
