import { useEffect, useState } from 'react';
import { FilterInput, GetPinnedGroupsQueryResult, Group_Status, TogglePinGroupMutationFn, useGetPinnedGroupsLazyQuery } from '../../generated/graphql';
import { useGroupHook, getGroups, sortGroups, GroupBase, writeToastMessage } from '../../v2/hooks/GroupHook';
import { getTotalPageLoadEvent } from '../../latencyTracker';

export type HomeGroupHook = ReturnType<typeof useHomeGroupHook>;

interface HomeGroupHookProps {
  teamId: number;
  orgId: number;
  teamName: string;
  orgName: string;
  pageName: string;
  filterInput?: FilterInput;
  status?: Group_Status;
  page?: number;
  pageSize?: number;
  teamUuid?: string;
  email?: string;
  homePage?: boolean;
  sentencesTake?: number;
}
export const useHomeGroupHook = (props: HomeGroupHookProps) => {
  const groupHook = useGroupHook(props);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [getPinnedGroupsQuery, pinnedGroupsQuery] = useGetPinnedGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId: props.teamId,
      belongs: true,
      sentencesTake: 0,
      take: props.pageSize,
      filterInput: props.filterInput ?? {},
      status: Group_Status.Monitored,
    },
  });
  useEffect(() => {
    const loadGroups = async () => {
      // this clears the current groups so that the loading indicator shows
      groupHook.setGroups([]);
      const [pinnedQuery] = await Promise.all([
        //@ts-ignore
        getPinnedGroupsQuery({
          variables: { ...groupHook.groupsQuery.variables, filterInput: props.filterInput ?? {}, sentencesTake: 0, teamId: props.teamId, skip: 0 },
        }),
      ]);
      groupHook.setGroups([...getGroups(pinnedQuery.data?.getGroups).sort(sortGroups)]);
    };
    if (props.teamId) {
      loadGroups().then(() => {
        setInitialLoadComplete(true);
        const event = getTotalPageLoadEvent({ view: 'home' });
        window.dispatchEvent(event);
        return;
      });
    }
  }, [props.teamId, props.orgId, props.filterInput]);

  return {
    isLoading: !pinnedGroupsQuery.called || pinnedGroupsQuery.loading,
    loadingPins: pinnedGroupsQuery.loading,
    ...groupHook,
    loadMore: async (startIndex: number, pageSize: number) => {
      //@ts-ignore
      const pinnedQuery = await getPinnedGroupsQuery({ variables: { ...groupHook.groupsQuery.variables, take: pageSize, skip: startIndex } });
      groupHook.setGroups((prev) => [...(prev ?? []), ...getGroups(pinnedQuery?.data?.getGroups).sort(sortGroups)]);
    },

    togglePinGroup: (groupId: string, cb?: () => void) =>
      togglePinGroupHome(
        groupId,
        props.teamId,
        groupHook.setGroups,
        groupHook.currentGroup,
        groupHook.setCurrentGroup,
        pinnedGroupsQuery,
        groupHook.togglePinGroupMutation,
        groupHook.groups,
        cb
      ),
    initialLoadComplete,
  };
};

/**
 *  Todo both togglePinGroupHome and discardHomeGroup need to reference 'groups' instead of the 'GetPinnedGroupsQueryResult' object.
 */
const togglePinGroupHome = (
  groupId: string,
  teamId: number,
  setPinnedGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  getPinnedGroups: GetPinnedGroupsQueryResult,
  togglePinGroupMutation: TogglePinGroupMutationFn,
  pinnedGroups: GroupBase[],
  cb?: () => void
) => {
  togglePinGroupMutation({
    variables: { teamId, groupId },
    onCompleted: async (data) => {
      if (currentGroup) {
        /** remove the group from the home page pinned groups */
        const pinned = !currentGroup.pinnedByUser;
        setCurrentGroup({ ...currentGroup, pinnedByUser: pinned });
        const groups = await getPinnedGroups.refetch({ ...getPinnedGroups.variables, skip: 0, take: pinnedGroups.length });
        writeToastMessage(`Group ${pinned ? 'Pinned' : 'Unpinned'}`);
        setPinnedGroups([...getGroups(groups.data.getGroups).sort(sortGroups)]);
      }
      cb?.();
    },
  });
};
