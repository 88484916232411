import { Group_Membership_Action, useModifyEntryGroupMembershipMutation } from '../../generated/graphql';

const errorMessages = {
  add: 'Failed to add entry to the group',
  remove: 'Failed to remove entry from the group',
};

export const useEditGroupMembershipHook = () => {
  const [modifyMembership, _] = useModifyEntryGroupMembershipMutation();

  const handleModifyMembership = async ({
    groupId,
    teamId,
    entryId,
    action,
  }: {
    groupId: string;
    teamId: number;
    entryId: string;
    action: Group_Membership_Action;
  }) => {
    try {
      const modifyRes = await modifyMembership({
        variables: {
          entryId,
          groupId,
          teamId,
          action,
        },
      });
      return modifyRes.data?.modifyEntryGroupMembership?.groupMemberships ?? [];
    } catch (err) {
      console.error(err);
      throw new Error(errorMessages[action]);
    }
  };

  return { handleModifyMembership };
};
