import { useState } from 'react';
import Button, { ButtonVariant } from '../baseComponents/Button';
import logoUnwrap from '../../assets/unwrap_logo.svg';
import { useCreateSecretMutation } from '../../generated/graphql';
import { ApolloError } from '@apollo/client';

export default function SecretPage() {
  const [secretValue, setSecretValue] = useState<string | undefined>(undefined);
  const [secretTitle, setSecretTitle] = useState<string | undefined>(undefined);
  const [createSecret] = useCreateSecretMutation();
  const [errorDisplay, setError] = useState<string | undefined>(undefined);
  const [successDisplay, setSuccess] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadSecret = async (secretTitle: string | undefined, secretValue: string | undefined) => {
    setLoading(true);
    setError(undefined);
    setSuccess(undefined);

    if (!secretTitle || !secretValue) {
      setError('Please enter a secret name and value');
      return;
    }
    // check for invalid characters being anything other than alphanumeric, -/_+=.@!
    const secretNameRegex = /^[a-zA-Z0-9-_=+./@]+$/;
    if (!secretNameRegex.test(secretTitle)) {
      setError('Secret Names cannot contain spaces and must only contain alphanumeric characters and the following: -/_+=.@!');
      return;
    }
    try {
      const response = await createSecret({
        variables: {
          secretName: secretTitle,
          secretValue: secretValue,
        },
      });
      setSuccess(`Successfully uploaded secret ${response.data?.createSecret}`);
    } catch (error: unknown) {
      if (error instanceof ApolloError) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex min-h-screen bg-milk font-sofiapro">
      <div className="flex flex-1 flex-col justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logoUnwrap} alt="Workflow" />
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  uploadSecret(secretTitle, secretValue);
                  e.preventDefault();
                }}
              >
                <div className="space-y-1">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Secret Name
                  </label>
                  <div className="mt-1">
                    <div className="text-xs text-gray-500">This is the name of the secret. This value is not actually encrypted.</div>
                    <input
                      id="secret-name"
                      name="text"
                      type="text"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setSecretTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Secret Value
                  </label>
                  <div className="text-xs text-gray-500">
                    This is where you enter the secret value. This value is encrypted. You can paste anything here a json object, a string, etc.
                  </div>
                  <div className="mt-1">
                    <textarea
                      placeholder={'plain text'}
                      required
                      className="focus:outline-nonebg-white h-full w-full flex-grow resize-none rounded-md border border-gray-300 py-2 pl-3 pr-14 text-blueberry shadow-sm focus:border-gray-300 focus:outline-none focus:ring-0 sm:text-sm"
                      value={secretValue}
                      onChange={(e) => setSecretValue(e.target.value)}
                    />
                  </div>
                </div>
                {errorDisplay && (
                  <div className="mt-4">
                    <h1 id="error-message" className="text-sm font-medium text-red-600">
                      {errorDisplay}
                    </h1>
                  </div>
                )}
                {successDisplay && (
                  <div className="mt-4">
                    <h1 id="error-message" className="text-sm font-medium text-green-600">
                      {successDisplay}
                    </h1>
                  </div>
                )}

                <div className="flex flex-col gap-y-4">
                  <Button id="sign-in-button" variant={ButtonVariant.Primary} text={'Save'} submit loadingConfirm={loading} expandWidth />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
