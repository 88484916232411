import {
  Chart_Bin_Type,
  Digest_Group_Status,
  FilterInput,
  InsightCollection,
  Insight_Collection_Type,
  Insight_Status,
  useGetInsightPreviewEntriesQuery,
} from '../../../../generated/graphql';
import Modal from '../../../baseComponents/Modal';
import { Dialog, Switch } from '@headlessui/react';
import { VirtualizedComboBox } from '../../VirtualizedComboBox';
import Button, { ButtonVariant } from '../../../baseComponents/Button';
import Form from '../../../../baseComponents/Form';
import { InsightAction, InsightPayload, InsightState } from '../../../../reducers/insights/insightReducer';
import { FilterHook } from '../../../hooks/FilterHook';
import FeedbackEntryCard from '../../FeedbackEntryCard';
import { capitalizeFirstLetter, classNames } from '../../../../v2/util';
import { EMPTY_DROPDOWN_ITEM, EMPTY_TITLE } from '../../../../reducers/utilities/consts';
import { getDropDownSelectionsFromEnum, getEnumValue } from '../../../../reducers/utilities/enumHandling';
import { RowLabel, RowWrapper, RowWrapperHalfSpan } from '../../../baseComponents/RowWrapper';
import InsightPreviewChart from '../insightCharts/InsightPreviewChart';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import LoadingSpinner from '../../../baseComponents/LoadingSpinner';
import { useEffect } from 'react';
import { getGroupLink } from '../../../../v2/hooks/GroupHook';
import { generateChartNavigationURL } from '../../CustomChartCard';
import Toggle from '../../Toggle';
import { FilterManager } from '../../../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../../../sections/Filters/FiltersUtil';
import { DatePicker } from '../../../baseComponents/DatePicker';

interface MutateInsightModalProps {
  teamId: number;
  isModalOpen: boolean;
  filterInput: FilterInput;
  setOpen: (state: boolean) => void;
  collection: InsightCollection;
  /**
   * This is responsible for saveing the insight and closing the modal
   * @returns
   */
  saveInsightCloseModal: () => void;
  filterHook: FilterHook;
  digestDisplay: JSX.Element;
  miState: InsightState;
  dispatch: React.Dispatch<{
    type: InsightAction;
    payload: InsightPayload;
  }>;
}

/**
 *
 * @returns
 */
const MutateInsightModal = (props: MutateInsightModalProps): JSX.Element => {
  const { isModalOpen, setOpen, filterInput, filterHook, dispatch, miState } = props;

  const canSaveInsight = (): boolean => {
    if (
      miState.text === EMPTY_TITLE ||
      miState.title === EMPTY_TITLE ||
      miState.feedbackStatus === EMPTY_DROPDOWN_ITEM ||
      miState.insightStatus === EMPTY_DROPDOWN_ITEM ||
      miState.chartBin === EMPTY_DROPDOWN_ITEM
    ) {
      return false;
    }
    return true;
  };
  // get preview insight
  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setOpen(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={miState.createSaveLoading || !canSaveInsight()}
            onClick={() => {
              props.saveInsightCloseModal();
            }}
            loadingConfirm={miState.createSaveLoading}
          ></Button>
        </div>
      </div>
    );
  };

  const collectionLink = getCollectionLink({ collection: miState.collection, filterInput, teamId: miState.teamId, orgId: miState.orgId });

  return (
    <Modal open={isModalOpen} setOpen={setOpen} width="w-[84rem]">
      <div className="mt-3 text-center sm:mt-5 text-blueberry relative">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Create/Edit Insight
        </Dialog.Title>
        {miState.collection.title ? (
          <a href={collectionLink} target="_blank" rel="noreferrer" className="ring-0 hover:underline focus:outline-none">
            <b>{capitalizeFirstLetter(miState.collection.type)}</b> - {miState.collection.title}
          </a>
        ) : null}
        {miState.checkingExistingInsightLoading ? (
          <div className="absolute w-full h-full z-50 rounded-lg bg-gray-400 bg-opacity-80 flex flex-col ">
            <div className="flex flex-col gap-y-6 pt-24">
              <LoadingSpinner />
              <p className="text-xl font-semibold">Checking for existing insight...</p>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={classNames('flex flex-col gap-y-8 h-full', miState.checkingExistingInsightLoading ? 'blur-sm' : '')}>
          <div className="flex flex-row gap-x-2 w-full min-h-[14rem]">
            {/* Chart */}
            <div className="w-1/2">
              <InsightPreviewChart
                teamId={props.teamId}
                collectionId={props.collection.id}
                collectionType={props.collection.type}
                filterInput={filterHook.filters}
                chartBin={getEnumValue(Chart_Bin_Type, miState.chartBin)}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col gap-y-2 items-start text-left w-full">
                {props.digestDisplay}
                <RowWrapper>
                  <RowLabel label="Insight Title" />
                  <input
                    type="text"
                    className="w-full rounded-md border border-gray-300 p-2"
                    value={miState.title}
                    onChange={(e) => dispatch({ type: InsightAction.SetInsightTitle, payload: { text: e.target.value } })}
                  />
                </RowWrapper>
                <RowWrapper>
                  <RowLabel label="Insight Description" />
                  <textarea
                    className="w-full rounded-md border border-gray-300 p-2"
                    value={miState.text}
                    onChange={(e) => dispatch({ type: InsightAction.SetInsightText, payload: { text: e.target.value } })}
                  />
                </RowWrapper>
                <RowWrapper>
                  <RowWrapperHalfSpan>
                    <RowLabel label="Trend" />
                    <VirtualizedComboBox
                      comboBoxData={getDropDownSelectionsFromEnum(Digest_Group_Status)}
                      setSelectedItem={(item) => {
                        //Trend Status is missing its implementation on the backend. Old insights have it.
                        dispatch({ type: InsightAction.SetFeedbackStatus, payload: { status: item ?? EMPTY_DROPDOWN_ITEM } });
                      }}
                      selectedItem={miState.feedbackStatus}
                      placeholder="Choose Trend"
                    />
                  </RowWrapperHalfSpan>
                  <RowWrapperHalfSpan>
                    <RowLabel label="Status" />
                    <VirtualizedComboBox
                      comboBoxData={getDropDownSelectionsFromEnum(Insight_Status)}
                      setSelectedItem={(item) => {
                        //Trend Status is missing its implementation on the backend. Old insights have it.
                        dispatch({ type: InsightAction.SetInsightStatus, payload: { selection: item ?? EMPTY_DROPDOWN_ITEM } });
                      }}
                      selectedItem={miState.insightStatus}
                      placeholder="Select a status"
                    />
                  </RowWrapperHalfSpan>
                </RowWrapper>
                <RowWrapper>
                  <RowWrapperHalfSpan>
                    <RowLabel label="Expiration" />
                    <DatePicker
                      date={miState.expiration}
                      onChange={(date: Date) => {
                        dispatch({ type: InsightAction.SetInsightExpiration, payload: { date: date } });
                      }}
                    />
                  </RowWrapperHalfSpan>
                  <RowWrapperHalfSpan>
                    <RowLabel label="Chart Bin" />
                    <VirtualizedComboBox
                      comboBoxData={getDropDownSelectionsFromEnum(Chart_Bin_Type)}
                      setSelectedItem={(item) => {
                        //Trend Status is missing its implementation on the backend. Old insights have it.
                        dispatch({ type: InsightAction.SetChartBin, payload: { selection: item ?? EMPTY_DROPDOWN_ITEM } });
                      }}
                      selectedItem={miState.chartBin}
                      placeholder="Select bin config"
                    />
                  </RowWrapperHalfSpan>
                </RowWrapper>
                <RowWrapper>
                  <RowLabel label="Exclude From Digest" />
                  <Toggle
                    initialState={miState.excludeFromDigest}
                    value={miState.excludeFromDigest}
                    onSwitch={(state) => {
                      dispatch({ type: InsightAction.SetExcludeFromDigest, payload: { exclude: !miState.excludeFromDigest } });
                    }}
                  />
                </RowWrapper>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start -my-5">
            <FilterManager
              pageName={''}
              filterHook={filterHook}
              dataTypeToFilter={'groupPage'}
              displayMode={FilterManagerDisplayMode.GroupPage}
              startingFilterInput={filterInput}
            />
          </div>
          {/* Entries list below */}
          <EntriesSection miState={miState} dispatch={dispatch} filterInput={filterHook.filters} />
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            // we don't want the modal to immediately close when the submit button is hit. this just prevents the modal closing prematurely.
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
};

export const getCollectionLink = ({
  collection,
  filterInput,
  teamId,
  orgId,
}: {
  collection: InsightCollection;
  filterInput: FilterInput;
  teamId: number;
  orgId: number;
}) => {
  let link = '';
  if (collection.type === Insight_Collection_Type.Group) link = getGroupLink(collection.id, filterInput, teamId, orgId, 'group', true);
  if (collection.type === Insight_Collection_Type.Chart) link = generateChartNavigationURL({ chartId: Number(collection.id), filterInput, teamId, orgId });
  return link;
};

const EntriesSection = (props: {
  miState: InsightState;
  filterInput: FilterInput;
  dispatch: React.Dispatch<{
    type: InsightAction;
    payload: InsightPayload;
  }>;
}) => {
  const { miState, dispatch, filterInput } = props;
  const { loading, refetch } = useGetInsightPreviewEntriesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId: miState.teamId,
      filterInput: filterInput,
      collectionId: miState.collection.id,
      collectionType: miState.collection.type,
    },
    onCompleted: (data) => {
      dispatch({ type: InsightAction.SetPreviewEntries, payload: data.getInsightPreviewEntries });
    },
  });

  useEffect(() => {
    refetch();
  }, [miState.chosenDigest]);

  // need to use loading here to return a loading state
  return (
    <div className="flex flex-col gap-y-2 text-left h-[42rem] overflow-y-auto ">
      {miState.previewEntries.map((entry) => (
        <div key={entry.entry.id} className="flex flex-row gap-x-4 items-center">
          <div className="items-center">
            {/* toggle button */}
            <Switch
              checked={entry.isSelected}
              onChange={(checked) => dispatch({ type: InsightAction.SetEntrySelected, payload: { selected: checked, entryId: entry.entry.id } })}
              disabled={!entry.isSelected && !miState.canSelectMoreEntries}
              className={classNames(
                entry.isSelected ? 'bg-blueberry' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blueberry focus:ring-offset-2'
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={classNames(
                  entry.isSelected ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>

          <div className="w-full">
            <FeedbackEntryCard entry={entry.entry} key={entry.entry.id} compact={true} />
          </div>
        </div>
      ))}
      {loading ? (
        <>
          <LoadingEntryCard />
          <LoadingEntryCard />
          <LoadingEntryCard />
          <LoadingEntryCard />
          <LoadingEntryCard />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
const LoadingEntryCard = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70"
    >
      <div className="space-y-3 justify-center items-center flex py-8 h-[275px]">
        <ChartBarIcon className="h-32 w-32 text-gray-300" />
      </div>
    </div>
  );
};

export default MutateInsightModal;
