import { GroupDataFragment, Group_Status, Group_Type } from '../../generated/graphql';

export const defaultGroup: GroupDataFragment = {
  id: '0',
  title: '',
  creator: {
    isUnwrapGenerated: false,
  },
  isExactMatch: false,
  isPinnedByUser: false,
  centroidText: '',
  uniqueEntries: 0,
  type: Group_Type.ClusterSearch,
  status: Group_Status.Unmonitored,
  progress: 0,
  processing: false,
  totalDescendents: 0,
  statistics: {
    denominator: {
      denominatorFiltered: 0,
      denominatorUnfiltered: 0,
    },
  },
};

export const checkExactMatch = (str: string) => {
  const regex = /^"([^"]+)"$/;
  return regex.test(str);
};
